import { appConfig } from 'app-config'

const apiURL = appConfig.api.baseUrl
export interface RequestConfig extends RequestInit {
  data?: object
  token?: string
  customConfig?: RequestInit
  isRetry?: boolean
}

export class AuthenticationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'AuthenticationError'
  }
}

async function client(
  endpoint: any,
  { data, token, headers: customHeaders, customConfig }: RequestConfig = {}
): Promise<any> {
  const headers: any = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  if (data) {
    headers['Content-Type'] = 'application/json'
  }
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...headers,
      ...customHeaders,
    },
    ...customConfig,
  }

  return window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        // If we're already retrying or refresh failed, throw authentication error
        throw new AuthenticationError('Session expired')
      }

      const responseData = await response.json()
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
}

export { client }
