import { appConfig } from 'app-config'
import {
  AccessTokenV1,
  CreateSessionResultV1,
  UserActivationV1,
  UserCreateActivationResultV1,
  UserRecoverV1,
  UserLoginV1,
} from 'types/api/v1'
import { reset } from 'instrument'
const apiURL = appConfig.api.baseUrl

export interface AuthUser extends CreateSessionResultV1 {}
export interface CreateUserAccount extends UserActivationV1 {}
export interface CreateUserAccountResult extends UserCreateActivationResultV1 {}

//We define our own client here because the auth service should be separate from the api client. The API client needs to handle 401 errors and logout differently than the auth service
async function client(
  endpoint: any,
  {
    data,
    token,
    headers: customHeaders,
    customConfig,
  }: { data?: object; token?: string; headers?: any; customConfig?: any } = {}
): Promise<any> {
  const headers: any = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  if (data) {
    headers['Content-Type'] = 'application/json'
  }
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...headers,
      ...customHeaders,
    },
    ...customConfig,
  }

  return window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      let responseData = { message: 'Error fulfilling request' }
      const contentType = response.headers.get('content-type')
      if (contentType?.includes('application/json')) {
        responseData = await response.json()
      }
      if (response.ok) {
        return responseData
      } else {
        return Promise.reject(responseData)
      }
    })
}

const localStorageKey = '__auth_provider_token__'
const refreshTokenKey = '__auth_provider_refresh_token__'

const refresh = async (): Promise<AuthUser | null> => {
  let user: AuthUser | null = null
  const refreshToken = window.localStorage.getItem(refreshTokenKey)

  if (refreshToken) {
    user = await client('v2/users/token/refresh', {
      data: { refreshToken },
      customConfig: { method: 'POST' },
    })
      .then((user: AuthUser) => {
        window.localStorage.setItem(
          localStorageKey,
          JSON.stringify(
            user.accessTokens!.find((t) => t.type === 'AccessToken')
          )
        )
        if (user.refreshToken) {
          window.localStorage.setItem(refreshTokenKey, user.refreshToken)
        }
        return user
      })
      .catch(() => {
        return null
      })
  }
  return user
}

const getToken = (): AccessTokenV1 | null => {
  const tokenString = window.localStorage.getItem(localStorageKey)
  let token = null
  if (tokenString) {
    token = JSON.parse(tokenString)
  }
  return token
}

const getTokenString = (): string | null | undefined => getToken()?.token

const login = (userLogin: UserLoginV1): Promise<AuthUser> => {
  return client('v2/users/login', { data: userLogin }).then(
    (user: AuthUser) => {
      // Store access token
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify(user.accessTokens!.find((t) => t.type === 'AccessToken'))
      )

      if (user.refreshToken) {
        window.localStorage.setItem(refreshTokenKey, user.refreshToken)
      }
      return user
    }
  )
}

const logout = async (): Promise<any> => {
  const token = getTokenString()
  const refreshToken = window.localStorage.getItem(refreshTokenKey)
  window.localStorage.removeItem(localStorageKey)
  window.localStorage.removeItem(refreshTokenKey)
  reset()

  if (token) {
    return client('v1/users/logout', {
      token,
      data: refreshToken ? { refreshToken } : undefined,
      customConfig: { method: 'POST' },
    })
  }
  return Promise.resolve()
}

const createAccount = async (
  account: UserActivationV1
): Promise<UserCreateActivationResultV1> => {
  return await client('v1/users/activation', { data: account }).then(
    (user: UserCreateActivationResultV1) => {
      return user
    }
  )
}

const recover = async (email: string): Promise<any> => {
  return client('v1/users/recover', {
    data: {
      email,
    } as UserRecoverV1,
  })
}

export { login, getToken, logout, refresh, createAccount, recover }
