import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useAuth } from 'context/auth-context'
import { CloseIcon, ListIcon, LogoutIcon, MenuIcon } from './icons'

function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const { user, logout } = useAuth()

  const navigate = useNavigate()
  const handleClick = (path: string) => {
    setIsOpen(false)
    navigate(path)
  }
  const HeaderContainer = () => (
    <header className=" top-0 flex items-center justify-between bg-white px-4 py-3">
      <button
        onClick={() => handleClick('/')}
        className="flex items-center rounded-xl hover:bg-gray-200"
      >
        <img src="/images/otr-cloud.png" alt="Logo" className="h-12" />
      </button>

      <button
        role="button"
        onClick={() => setIsOpen((prev) => !prev)}
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
    </header>
  )

  return (
    <>
      {isOpen ? (
        <div className="fixed inset-0 z-50 flex items-center justify-end md:justify-end">
          <div className="relative h-full w-full transform bg-[#F9FAFB] shadow-lg transition-transform duration-200 ease-in-out md:w-96">
            <HeaderContainer />
            <div className="mx-4 pt-10">
              <div></div>
              <button
                onClick={() => handleClick('/')}
                role="button"
                className="flex w-full items-center justify-center rounded-lg bg-white px-3 py-2"
              >
                <ListIcon />
                <span className="order-2 ml-[15px] font-semibold leading-6 text-[#344054]">
                  Vehicles
                </span>
              </button>

              <div className="absolute bottom-0 left-0 right-0 mx-4 border-t px-2 pb-12 pt-6">
                <div>
                  <div className="flex items-center justify-between text-sm font-semibold leading-5 text-[#344054]">
                    <span>
                      {`${user?.identity?.firstName} ${user?.identity?.lastName}`}
                    </span>
                    <button
                      role="button"
                      onClick={() => void logout()}
                      aria-label="Logout"
                    >
                      <LogoutIcon />
                    </button>
                  </div>
                  <div className="break-words text-sm leading-5 text-[#475467]">
                    {user?.identity?.email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <HeaderContainer />
      )}
    </>
  )
}

export { Header }
