import clsx from 'clsx'

enum BadgeLevel {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  PRIMARY = 'primary',
}

const Badge = ({
  children,
  className = '',
  level = BadgeLevel.INFO,
  ...props
}: {
  children: React.ReactNode
  className?: string
  level?: BadgeLevel
  props?: React.HTMLProps<HTMLSpanElement>
}) => {
  const background = clsx(
    'inline-flex items-center rounded-2xl text-center text-xs font-semibold leading-[18px]',
    level === BadgeLevel.WARNING
      ? 'bg-[#FDF2FA]'
      : level === BadgeLevel.SUCCESS
        ? 'bg-[#ECFDF3]'
        : level === BadgeLevel.PRIMARY
          ? 'bg-blue-100'
          : 'bg-[#EEEFEF]',
    className
  )

  const dotCLasses = clsx(
    'h-[6px] w-[6px] rounded-full',
    level === BadgeLevel.WARNING
      ? 'bg-[#E63998]'
      : level === BadgeLevel.SUCCESS
        ? 'bg-[#339933]'
        : level === BadgeLevel.PRIMARY
          ? 'bg-[#2E90FA]'
          : 'bg-[#667085]'
  )

  const textClasses = clsx(
    'py-[2px] pr-2 ',
    level === BadgeLevel.WARNING
      ? 'text-[#C11574]'
      : level === BadgeLevel.SUCCESS
        ? 'text-[#339933]'
        : level === BadgeLevel.PRIMARY
          ? 'text-[#175CD3]'
          : 'text-[#344054]'
  )

  return (
    <span className={background} {...props}>
      <div className="px-[7px] py-2">
        <div className={dotCLasses}></div>
      </div>

      <div className={textClasses}>{children}</div>
    </span>
  )
}

export { Badge, BadgeLevel }
