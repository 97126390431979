import { FaultCodeResultV1NonPaginatedCollectionResponseV1 } from 'types/api/v1'

import { Fragment, useCallback, useEffect, useState } from 'react'
import {
  useFindLatestFaultCodes,
  useFindVehicle,
  useVehicleSearch,
} from 'utils/vehicles'
import { NotFoundPlaceholder } from '../not-found-placeholder'
import { FaultCodeViewModel } from 'types/fault-codes'
import { Badge, BadgeLevel } from 'ui/badge'
import { FaultCodeCard } from './fault-code-card'
import { useVehiclePrint } from '../use-vehicle-print'
import { Scrollable } from 'ui/scrollable'
import { useFeatureFlag } from 'utils/use-feature-flag'
import clsx from 'clsx'

function FaultCodesLatest() {
  const { selectFaultCode, faultCodeId } = useVehicleSearch()
  const { vin } = useFindVehicle()
  const displayCount = 5

  const { PrintButton, PrintWrapper } = useVehiclePrint(
    `fault-codes-latest-${vin}`
  )

  const { faultCodes: latestFaultCodesResult } = useFindLatestFaultCodes(vin)
  const { data, isError } = latestFaultCodesResult

  const faultCodeResponse =
    data as FaultCodeResultV1NonPaginatedCollectionResponseV1

  const [faultCodes, setFaultCodes] = useState<FaultCodeViewModel[]>(
    Array(displayCount)
      .fill(null)
      .map(() => new FaultCodeViewModel())
  )

  const [count, setCount] = useState<number | undefined>()

  useEffect(() => {
    if (faultCodeResponse?.data) {
      setFaultCodes(
        faultCodeResponse.data.map(
          (faultCode) => new FaultCodeViewModel(faultCode)
        )
      )
      setCount(faultCodeResponse.data.length)
    }
  }, [faultCodeResponse, count])

  const handleFaultCodeSelect = useCallback(
    (faultCode: FaultCodeViewModel) => {
      if (faultCode.id) {
        selectFaultCode(faultCode.id)
      }
    },
    [selectFaultCode]
  )

  const { value: canAccessVehicleInfo } = useFeatureFlag(
    'vehicleDetailInfoTab',
    false
  )

  if (isError) {
    return <div>Error...</div>
  }

  return (
    <>
      {!faultCodeId && (
        <PrintWrapper vin={vin}>
          <div className="relative">
            <div className="flex  flex-col justify-between lg:flex-row">
              <div
                className={clsx(
                  'ml-5 flex flex-wrap items-center',
                  canAccessVehicleInfo && 'pt-5 lg:pt-2',
                  !canAccessVehicleInfo && 'pt-5'
                )}
              >
                {' '}
                <h2 className="font-medium leading-7 text-[#2E2925]">
                  Fault Codes
                </h2>
                <Badge level={BadgeLevel.PRIMARY} className="ml-2">
                  {faultCodeResponse?.data?.length ?? ''}
                </Badge>
              </div>
              <div
                className={clsx(
                  'mb-2 mt-6 lg:mb-0 print:hidden',
                  !canAccessVehicleInfo &&
                    'mx-4 lg:relative lg:bottom-[54px] lg:right-8 lg:mx-0 lg:mr-0 lg:mt-0',
                  canAccessVehicleInfo &&
                    'mr-4 flex items-center pl-4 lg:mt-2 lg:pl-0'
                )}
              >
                <PrintButton className="w-full lg:w-auto" />
              </div>
            </div>
          </div>

          <Scrollable
            className={`mt-4 overflow-auto rounded-b-lg  md:max-h-[calc(100vh-474px)] lg:max-h-[calc(100vh-393px)]  print:max-h-full print:overflow-visible`}
          >
            {faultCodes.length === 0 ? (
              <NotFoundPlaceholder
                className="h-96"
                description={'No Fault Codes Found'}
              />
            ) : (
              faultCodes.map((faultCode, index) => {
                const isLastRow = index === faultCodes.length - 1
                return (
                  <Fragment key={faultCode.id}>
                    <FaultCodeCard
                      faultCode={faultCode}
                      onClick={() => {
                        handleFaultCodeSelect(faultCode)
                      }}
                      className={`min-w-[950px] print:min-w-0 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} ${isLastRow && 'rounded-b-lg hover:rounded-b-lg'}`}
                    />
                  </Fragment>
                )
              })
            )}
          </Scrollable>
        </PrintWrapper>
      )}
    </>
  )
}

export { FaultCodesLatest }
