import { FaultCodeDetail } from 'features/vehicles/fault-codes/fault-code-detail'
import { VehicleDetail } from 'features/vehicles/vehicle-detail'
import { Path, useLocation, useNavigate } from 'react-router-dom'
import { CaretLeftIcon } from 'ui/icons'
import { useVehicleSearch, useFindVehicle } from 'utils/vehicles'
import { useEffect, useRef } from 'react'
import { VehicleInfoPanel } from 'features/vehicles/vehicle-info-panel'
import { NotFoundPlaceholder } from 'features/vehicles/not-found-placeholder'

function Vehicle() {
  const navigate = useNavigate()
  const location = useLocation()

  const { faultCodeId, clearFaultCode } = useVehicleSearch()
  const { vin, isError } = useFindVehicle()
  const fromLocationRef = useRef<Path | null>(null)

  useEffect(() => {
    if (fromLocationRef.current === null) {
      fromLocationRef.current = (location.state as { from: Path })?.from
    }
  }, [location.state])

  const backToVehicles = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.currentTarget.blur()

    const fromPath = fromLocationRef?.current?.pathname
    const fromSearch = fromLocationRef?.current?.search
    const currentFromPath = (location.state as { from: Path })?.from?.pathname
    const currentFromSearch = (location.state as { from: Path })?.from?.search
    if (fromPath === '/vehicles') {
      const current = currentFromPath + currentFromSearch
      const previous = fromPath + fromSearch
      if (current === previous) {
        navigate(-1)
      } else {
        navigate(previous)
      }
    } else {
      navigate('/vehicles')
    }
  }

  const backToVehicle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.currentTarget.blur()
    clearFaultCode()
  }

  return (
    <div className=" h-full w-full bg-gray-50 md:overflow-auto">
      <div className="mx-4 md:mx-8 ">
        <div className="mb-4 flex items-center justify-items-center pt-6 md:pt-8">
          <button
            onClick={faultCodeId ? backToVehicle : backToVehicles}
            className="ml-[1.5px] rounded-full border border-gray-50 p-2 hover:border-otr-primary-dark hover:text-stone-100 hover:shadow focus:outline-none focus:ring-2 focus:ring-otr-secondary"
            aria-label={faultCodeId ? 'Back to vehicle' : 'Back to vehicles'}
          >
            <CaretLeftIcon />
          </button>
          <h1 className="pl-3 text-lg font-medium leading-[38px] text-gray-600">
            {faultCodeId ? 'Back' : 'Vehicles'}
          </h1>
        </div>
        {isError ? (
          <NotFoundPlaceholder
            className="h-96"
            title="Ooops"
            description="Vehicle not found"
          />
        ) : (
          <>
            <div className="md:max-w-[calc(100vw-346px)]">
              <VehicleInfoPanel />
            </div>
            <div className="mt-4 flex flex-col transition-all duration-500 ease-in-out">
              {vin && (
                <>
                  {faultCodeId && <FaultCodeDetail />}
                  <div className={faultCodeId ? 'hidden' : ''}>
                    <VehicleDetail />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export { Vehicle }
