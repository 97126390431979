import { appConfig } from 'app-config'
import TagManager from 'react-gtm-module'

export enum AnalyticsEvent {
  SELECT_VEHICLE_DETAIL_TAB = 'select_vehicle_detail_tab',
  SELECT_PDF_PRINT = 'select_pdf_print',
  SELECT_VEHICLE = 'select_vehicle',
  SELECT_FAULT_CODE = 'select_fault_code',
  SELECT_FIRST_PAGE = 'select_first_page',
  SELECT_PREVIOUS_PAGE = 'select_previous_page',
  SELECT_NEXT_PAGE = 'select_next_page',
}

function useAnalytics() {
  const enabled = appConfig.firebase.enabled

  if (!enabled) {
    return {
      trackEvent: () => {
        console.log('Firebase is not enabled')
      },
    }
  }

  return {
    trackEvent: (
      event: AnalyticsEvent,
      data: Record<string, any> | undefined = undefined
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...data,
        },
      })
    },
  }
}

export { useAnalytics }
