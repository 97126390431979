import { FaultCodeResultV1PaginatedResponseV1 } from 'types/api/v1'
import { Fragment, useCallback, useEffect, useState } from 'react'
import {
  FaultCodeFilter,
  useFindFaultCodeHistory,
  useFindVehicle,
  usePagination,
  useVehicleSearch,
} from 'utils/vehicles'
import { NotFoundPlaceholder } from '../not-found-placeholder'
import clsx from 'clsx'
import { FaultCodeViewModel } from 'types/fault-codes'
import { isEmpty } from 'utils/string-helpers'
import { Badge, BadgeLevel } from 'ui/badge'
import { FaultCodeCard } from './fault-code-card'
import { Paginator } from '../paginator'
import { useVehiclePrint } from '../use-vehicle-print'
import { Scrollable } from 'ui/scrollable'
import { useFeatureFlag } from 'utils/use-feature-flag'

function FilterToggle({
  value,
  handleChange,
}: {
  value: FaultCodeFilter
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const filterOptions = Object.values(FaultCodeFilter)
  return (
    <div className="flex overflow-hidden rounded-lg border border-[#D0D5DD] bg-white text-sm font-semibold text-stone-800">
      {filterOptions.map((option, index) => (
        <label
          key={option}
          className={clsx(
            'flex h-[34px] w-[70px] items-center justify-center text-center text-xs font-medium leading-5 transition-colors duration-300 ease-in-out',
            value === option
              ? ' bg-[#339933] text-white'
              : ' cursor-pointer text-[#2E2925] hover:bg-[#F0F2F4]',
            index !== 0 && 'border-l '
          )}
        >
          <input
            type="radio"
            name="faultCodeActivityFilter"
            value={option}
            checked={value === option}
            onChange={handleChange}
            className="sr-only"
            aria-label={option}
          />
          <span>{option}</span>
        </label>
      ))}
    </div>
  )
}

function FaultCodesHistory() {
  const { selectFaultCode, setFaultCodeFilter, faultCodeFilter, faultCodeId } =
    useVehicleSearch()
  const { vin } = useFindVehicle()
  const { clearPage, cursor } = usePagination()
  const { value: canAccessVehicleInfo } = useFeatureFlag(
    'vehicleDetailInfoTab',
    false
  )

  const displayCount = 10

  const { PrintButton, PrintWrapper } = useVehiclePrint(
    `fault-codes-history-${vin}`
  )

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFaultCodeFilter(event.target.value as FaultCodeFilter)
  }

  const [faultCodeFilterValue, setFaultCodeFilterValue] =
    useState<FaultCodeFilter>(FaultCodeFilter.ACTIVE)

  useEffect(() => {
    if (
      faultCodeFilter != null &&
      Object.values(FaultCodeFilter).includes(faultCodeFilter)
    ) {
      setFaultCodeFilterValue(faultCodeFilter)
    } else {
      setFaultCodeFilterValue(FaultCodeFilter.ALL)
    }
  }, [faultCodeFilter])

  const { faultCodes: faultCodesResult } = useFindFaultCodeHistory({
    count: displayCount,
    faultCodeFilter,
    cursor: isEmpty(cursor) ? undefined : cursor,
    vin,
  })

  const { data, isLoading, isError } = faultCodesResult
  const faultCodeResponse = data as FaultCodeResultV1PaginatedResponseV1

  const [faultCodes, setFaultCodes] = useState<FaultCodeViewModel[]>(
    Array(displayCount)
      .fill(null)
      .map(() => new FaultCodeViewModel())
  )

  useEffect(() => {
    if (faultCodeResponse?.data) {
      const newFaultCodes = faultCodeResponse.data.map(
        (faultCode) => new FaultCodeViewModel(faultCode)
      )
      if (JSON.stringify(newFaultCodes) !== JSON.stringify(faultCodes)) {
        if (isEmpty(faultCodeResponse.pagination?.links?.prev)) {
          clearPage()
        }
        setFaultCodes(newFaultCodes)
      }
    }
  }, [clearPage, faultCodeResponse, faultCodes])

  const handleFaultCodeSelect = useCallback(
    (faultCode: FaultCodeViewModel) => {
      if (faultCode.id) {
        selectFaultCode(faultCode.id)
      }
    },
    [selectFaultCode]
  )

  if (isError) {
    return <div>Error...</div>
  }

  return (
    <>
      {!faultCodeId && (
        <PrintWrapper vin={vin}>
          <div
            className={clsx(
              'relative print:flex print:flex-row print:items-center print:justify-between',
              canAccessVehicleInfo && 'lg:flex lg:justify-between'
            )}
          >
            <div className="flex flex-col justify-between lg:flex-row print:flex-row print:items-center print:justify-between">
              <div
                className={clsx(
                  'ml-5 flex flex-wrap items-center pt-5 print:pt-0',
                  canAccessVehicleInfo && 'lg:pt-2'
                )}
              >
                <h2 className="font-medium leading-7 text-[#2E2925]">
                  Fault Codes
                </h2>
                <Badge level={BadgeLevel.PRIMARY} className="ml-2">
                  {faultCodeResponse?.pagination?.totalCount ?? ''}
                </Badge>
              </div>
              {!canAccessVehicleInfo && (
                <>
                  <div className="mx-4 mb-2 mt-6 lg:relative lg:bottom-[54px] lg:right-8 lg:mx-0 lg:mb-0 lg:mr-0 lg:mt-0 print:hidden">
                    <PrintButton className="w-full flex-row lg:w-auto" />
                  </div>
                </>
              )}
            </div>
            {!canAccessVehicleInfo && (
              <div className="mx-4 mb-2 mt-4 flex items-center justify-between lg:absolute lg:right-8 lg:top-4 lg:mx-0 lg:mb-0 lg:mr-0 lg:mt-0">
                <FilterToggle
                  value={faultCodeFilterValue}
                  handleChange={handleFilterChange}
                />
              </div>
            )}
            {canAccessVehicleInfo && (
              <div className="mx-4 mb-2 mt-6 items-center gap-4 lg:mx-0 lg:mb-0 lg:mr-4 lg:mt-2 lg:flex lg:flex-row-reverse">
                <PrintButton className="w-full flex-row lg:w-auto" />
                <div className="mt-4 flex lg:mt-0">
                  <FilterToggle
                    value={faultCodeFilterValue}
                    handleChange={handleFilterChange}
                  />
                </div>
              </div>
            )}
          </div>
          <Scrollable
            className={`mt-4 overflow-auto rounded-b-lg md:max-h-[calc(100vh-596px)] lg:max-h-[calc(100vh-464px)] print:max-h-full print:overflow-visible`}
          >
            {faultCodes.length === 0 ? (
              <NotFoundPlaceholder
                className="h-96"
                description={`No ${faultCodeFilter == FaultCodeFilter.ACTIVE || faultCodeFilter == FaultCodeFilter.INACTIVE ? faultCodeFilter : faultCodeFilter === null ? FaultCodeFilter.ACTIVE.toString() : ''} Fault Codes Found`}
              />
            ) : (
              faultCodes.map((faultCode, index) => {
                return (
                  <Fragment key={faultCode.id}>
                    <FaultCodeCard
                      faultCode={faultCode}
                      onClick={() => {
                        handleFaultCodeSelect(faultCode)
                      }}
                      className={`min-w-[950px] print:min-w-0 ${isLoading && faultCode.startedAt ? 'bg-[#F0F2F4]' : index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                    />
                  </Fragment>
                )
              })
            )}
          </Scrollable>
          <Paginator pagination={faultCodeResponse?.pagination} />
        </PrintWrapper>
      )}
    </>
  )
}

export { FaultCodesHistory }
