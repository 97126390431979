import clsx from 'clsx'
import { VehiclesInfoPanel } from 'features/vehicles/vehicles-info-panel'
import { VehiclesTable } from 'features/vehicles/vehicles-table'

function Vehicles() {
  const classes = clsx(
    'mt-3 flex flex-col pb-8 md:max-w-[calc(100vw-352px)] md:pb-0',
    //Subtract Vehicles Info Panel + Vehicles Header with margin
    'md:max-h-[calc(100vh-250px)]'
  )

  return (
    <div className=" w-full bg-gray-50">
      <div className="mx-4 md:mx-8 ">
        <div className="mb-4 flex justify-between pt-6 md:pt-8">
          <h1 className="text-2xl font-semibold">Vehicles </h1>
        </div>
        <div>
          <div className=" md:max-w-[calc(100vw-352px)]">
            <VehiclesInfoPanel />
          </div>
          <div className={classes}>
            <VehiclesTable />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Vehicles }
