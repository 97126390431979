import { formatDateString } from 'utils/date-helpers'
import { FaultCodeResultV1 } from './api/v1'

export class FaultCodeViewModel {
  public id?: string
  public spn?: string | null
  public fmi?: string | null
  public isActive?: boolean
  public startedAt?: string
  public endedAt?: string
  public codeCount?: number
  public name?: string | null
  public isEnhanced?: boolean
  public sa?: string | null
  public codeSourceDescription?: string | null
  public oem?: string | null
  public label?: string | null

  constructor(faultCode?: FaultCodeResultV1) {
    if (faultCode) {
      this.id = faultCode.faultCodeId ?? Math.random().toString(36).slice(2, 11)
      this.spn =
        faultCode.spn !== undefined && faultCode.spn !== null
          ? String(faultCode?.spn)
          : 'n/a'
      this.fmi =
        faultCode.fmi !== undefined && faultCode.fmi !== null
          ? String(faultCode?.fmi)
          : 'n/a'

      this.isActive = faultCode.isActive ?? false
      this.startedAt = faultCode.startedAt
        ? formatDateString(faultCode.startedAt)
        : 'n/a'
      this.endedAt = faultCode.endedAt
        ? formatDateString(faultCode.endedAt)
        : 'n/a'
      this.codeCount = faultCode.codeCount ?? 0
      this.name = faultCode.name
      this.isEnhanced = faultCode.enhanced ?? false
      this.sa = String(faultCode?.codeSource) ?? 'n/a'
      this.codeSourceDescription = faultCode.codeSourceDescription
      this.oem = faultCode.oem
      this.label = ''

      if (faultCode.spn ?? faultCode.fmi ?? faultCode.codeSource) {
        this.label = `SA ${this?.sa} SPN ${this?.spn} FMI ${this?.fmi}`
      }

      if (this.oem) {
        if (this.label.length > 0) {
          this.label = this.label.concat(' | ')
        }
        this.label = this.label.concat(this.oem)
      }
    } else {
      this.id = Math.random().toString(36).substr(2, 9)
    }
  }
}
