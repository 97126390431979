export const ListIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 8L7 8M19 2L7 2M19 14L7 14M3 8C3 8.55228 2.55228 9 2 9C1.44772 9 1 8.55228 1 8C1 7.44772 1.44772 7 2 7C2.55228 7 3 7.44772 3 8ZM3 2C3 2.55228 2.55228 3 2 3C1.44772 3 1 2.55228 1 2C1 1.44772 1.44772 1 2 1C2.55228 1 3 1.44772 3 2ZM3 14C3 14.5523 2.55228 15 2 15C1.44772 15 1 14.5523 1 14C1 13.4477 1.44772 13 2 13C2.55228 13 3 13.4477 3 14Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MenuIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="4" width="24" height="2" rx="1" fill="#101828" />
    <rect y="11" width="24" height="2" rx="1" fill="#101828" />
    <rect y="18" width="24" height="2" rx="1" fill="#101828" />
  </svg>
)

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6673 1.33398L1.33398 14.6673M1.33398 1.33398L14.6673 14.6673"
      stroke="#101828"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const TruckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 10.5V3.15C11 2.30992 11 1.88988 10.8365 1.56901C10.6927 1.28677 10.4632 1.0573 10.181 0.91349C9.86012 0.75 9.44008 0.75 8.6 0.75H2.9C2.05992 0.75 1.63988 0.75 1.31901 0.91349C1.03677 1.0573 0.8073 1.28677 0.66349 1.56901C0.5 1.88988 0.5 2.30992 0.5 3.15V8.1C0.5 8.94008 0.5 9.36012 0.66349 9.68099C0.8073 9.96323 1.03677 10.1927 1.31901 10.3365C1.63988 10.5 2.05992 10.5 2.9 10.5H11ZM11 10.5H14.3C14.72 10.5 14.9301 10.5 15.0905 10.4183C15.2316 10.3463 15.3463 10.2316 15.4183 10.0905C15.5 9.93006 15.5 9.72004 15.5 9.3V7.24706C15.5 7.06361 15.5 6.97189 15.4793 6.88558C15.4609 6.80905 15.4306 6.73589 15.3895 6.66879C15.3431 6.5931 15.2782 6.52824 15.1485 6.39853L13.6015 4.85147C13.4718 4.72176 13.4069 4.6569 13.3312 4.61052C13.2641 4.5694 13.191 4.53909 13.1144 4.52072C13.0281 4.5 12.9364 4.5 12.7529 4.5H11M5.75 12.375C5.75 13.4105 4.91053 14.25 3.875 14.25C2.83947 14.25 2 13.4105 2 12.375C2 11.3395 2.83947 10.5 3.875 10.5C4.91053 10.5 5.75 11.3395 5.75 12.375ZM14 12.375C14 13.4105 13.1605 14.25 12.125 14.25C11.0895 14.25 10.25 13.4105 10.25 12.375C10.25 11.3395 11.0895 10.5 12.125 10.5C13.1605 10.5 14 11.3395 14 12.375Z"
      stroke="#6C757D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 7.83366H0.5M11.3333 1.16699V4.50033M4.66667 1.16699V4.50033M4.5 17.8337H11.5C12.9001 17.8337 13.6002 17.8337 14.135 17.5612C14.6054 17.3215 14.9878 16.939 15.2275 16.4686C15.5 15.9339 15.5 15.2338 15.5 13.8337V6.83366C15.5 5.43353 15.5 4.73346 15.2275 4.19868C14.9878 3.72828 14.6054 3.34583 14.135 3.10614C13.6002 2.83366 12.9001 2.83366 11.5 2.83366H4.5C3.09987 2.83366 2.3998 2.83366 1.86502 3.10614C1.39462 3.34583 1.01217 3.72828 0.772484 4.19868C0.5 4.73346 0.5 5.43353 0.5 6.83366V13.8337C0.5 15.2338 0.5 15.9339 0.772484 16.4686C1.01217 16.939 1.39462 17.3215 1.86502 17.5612C2.3998 17.8337 3.09987 17.8337 4.5 17.8337Z"
      stroke="#6C757D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DownArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.9987 0.833984V10.1673M5.9987 10.1673L10.6654 5.50065M5.9987 10.1673L1.33203 5.50065"
      stroke="#6C757D"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const UpArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 10.1673V0.833984M6 0.833984L1.33333 5.50065M6 0.833984L10.6667 5.50065"
      stroke="#6C757D"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.16797 2.6576C1.16797 1.8483 1.16797 1.44365 1.33671 1.22059C1.48371 1.02626 1.7084 0.906013 1.95163 0.89149C2.23083 0.87482 2.56752 1.09928 3.2409 1.5482L12.0039 7.39018C12.5603 7.76112 12.8385 7.94658 12.9354 8.18035C13.0202 8.38473 13.0202 8.61443 12.9354 8.8188C12.8385 9.05257 12.5603 9.23804 12.0039 9.60898L3.2409 15.451C2.56752 15.8999 2.23083 16.1243 1.95163 16.1077C1.7084 16.0931 1.48371 15.9729 1.33671 15.7786C1.16797 15.5555 1.16797 15.1509 1.16797 14.3416V2.6576Z"
      stroke="#6C757D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

//add a hastag icon with a similar style as the above icons
//looks like a pound sign
export const HashtagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 9H17M7 15H17M9 7V17M15 7V17"
      stroke="#6C757D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const CaretLeftIcon = ({
  color = '#101828',
  ...props
}: React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 1L0.999999 9L9 17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

interface PrintIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string
}
export const PrintIcon = ({ color = 'black', ...props }: PrintIconProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 6V4.2C17 3.0799 17 2.51984 16.782 2.09202C16.5903 1.71569 16.2843 1.40973 15.908 1.21799C15.4802 1 14.9201 1 13.8 1H8.2C7.0799 1 6.51984 1 6.09202 1.21799C5.71569 1.40973 5.40973 1.71569 5.21799 2.09202C5 2.51984 5 3.0799 5 4.2V6M5 17C4.07003 17 3.60504 17 3.22354 16.8978C2.18827 16.6204 1.37962 15.8117 1.10222 14.7765C1 14.395 1 13.93 1 13V10.8C1 9.11984 1 8.27976 1.32698 7.63803C1.6146 7.07354 2.07354 6.6146 2.63803 6.32698C3.27976 6 4.11984 6 5.8 6H16.2C17.8802 6 18.7202 6 19.362 6.32698C19.9265 6.6146 20.3854 7.07354 20.673 7.63803C21 8.27976 21 9.11984 21 10.8V13C21 13.93 21 14.395 20.8978 14.7765C20.6204 15.8117 19.8117 16.6204 18.7765 16.8978C18.395 17 17.93 17 17 17M14 9.5H17M8.2 21H13.8C14.9201 21 15.4802 21 15.908 20.782C16.2843 20.5903 16.5903 20.2843 16.782 19.908C17 19.4802 17 18.9201 17 17.8V16.2C17 15.0799 17 14.5198 16.782 14.092C16.5903 13.7157 16.2843 13.4097 15.908 13.218C15.4802 13 14.9201 13 13.8 13H8.2C7.0799 13 6.51984 13 6.09202 13.218C5.71569 13.4097 5.40973 13.7157 5.21799 14.092C5 14.5198 5 15.0799 5 16.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.07989 21 8.2 21Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.06065 6.00016C6.21739 5.55461 6.52675 5.1789 6.93395 4.93958C7.34116 4.70027 7.81991 4.61279 8.28544 4.69264C8.75096 4.77249 9.1732 5.01451 9.47737 5.37585C9.78154 5.73718 9.94802 6.19451 9.94732 6.66683C9.94732 8.00016 7.94732 8.66683 7.94732 8.66683M8.00065 11.3335H8.00732M14.6673 8.00016C14.6673 11.6821 11.6826 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6826 1.3335 14.6673 4.31826 14.6673 8.00016Z"
      stroke="#98A2B3"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
