const formatOptions = {
  month: 'numeric',
  day: 'numeric',
  year: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
} as Intl.DateTimeFormatOptions
export function formatDateString(date: string): string {
  return new Date(date).toLocaleString(undefined, formatOptions)
}

export function formatDate(date: Date): string {
  return date.toLocaleString(undefined, formatOptions)
}
