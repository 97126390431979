import clsx from 'clsx'

function NotFoundPlaceholder({
  className,
  title,
  description,
}: {
  className?: string
  title?: string
  description?: string
}) {
  const classes = clsx(
    'flex flex-col place-items-center justify-center text-center',
    className
  )
  return (
    <div className={classes}>
      <img src="/images/cloud.png" alt="Green Cloud" />
      <div className="">
        {title && (
          <div className="leading-12 mt-6 text-center text-4xl font-semibold tracking-[-0.02em] text-[#101828]">
            {title}
          </div>
        )}
        {description && (
          <div
            className={`${title ? 'mt-2' : 'mt-6'} max-w-[500px] text-center text-xl font-normal leading-[30px] text-[#667085]`}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  )
}

export { NotFoundPlaceholder }
