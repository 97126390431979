import { ActivationForm } from 'features/activation/activation-form'

function Activate() {
  return (
    <div className="flex h-[calc(100vh-72px)] w-full items-center justify-center  bg-gray-50 md:h-full">
      <div className="flex w-full flex-col items-center">
        <ActivationForm />
      </div>
    </div>
  )
}

export { Activate }
