import React from 'react'
import {
  OtrAdminControlsContext,
  OtrAdminControlsData,
} from './otr-admin-controls-context'

export function useOtrAdminControls(): OtrAdminControlsData {
  const context = React.useContext(OtrAdminControlsContext)
  if (!context) {
    throw new Error(
      'useOtrAdminSettings must be used within an OtrAdminSettingsProvider'
    )
  }
  return context
}
