import { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Skeleton from 'react-loading-skeleton'
import { InfoIcon } from 'ui/icons'

export interface InfoDetail {
  title?: string
  value?: string
  tooltip?: string // New tooltip prop
}

const Tooltip = ({
  content,
  isVisible,
  anchorEl,
}: {
  content: React.ReactNode
  isVisible: boolean
  anchorEl: HTMLElement | null
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    if (anchorEl && isVisible) {
      const rect = anchorEl.getBoundingClientRect()
      const isRightHalf = rect.left > window.innerWidth / 2
      const isBottomHalf = rect.top > window.innerHeight / 2

      setTooltipPosition({
        top: isBottomHalf ? rect.top - 10 : rect.bottom + 10,
        left: isRightHalf ? rect.right - 256 : rect.left,
      })
    }
  }, [isVisible, anchorEl])

  if (!isVisible) return null

  return ReactDOM.createPortal(
    <div
      className="fixed z-50 w-64 rounded-lg bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5"
      style={{
        top: `${tooltipPosition.top}px`,
        left: `${tooltipPosition.left}px`,
      }}
    >
      {content}
    </div>,
    document.body
  )
}

const Card = ({
  detail,
  className,
}: {
  detail: InfoDetail
  className?: string
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const iconRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={`rounded-lg border border-gray-200 bg-white p-5 shadow-sm ${className} relative`}
    >
      {detail.title === undefined ? (
        <Skeleton className="mb-2" height={12} width={150} />
      ) : (
        <div className="mb-2 flex items-center justify-between">
          <span className="text-xs font-medium leading-5 text-gray-500">
            {detail.title}
          </span>
          {detail.tooltip && (
            <div
              ref={iconRef}
              className="relative"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <InfoIcon aria-label={`What does ${detail.title} mean?`} />
              <Tooltip
                content={
                  <>
                    <div className="mb-2 text-xs font-semibold leading-[18px] text-[#101828]">
                      {detail.title}
                    </div>
                    <p className="text-xs font-medium leading-[18px] text-[#667085]">
                      {detail.tooltip}
                    </p>
                  </>
                }
                isVisible={showTooltip}
                anchorEl={iconRef.current}
              />
            </div>
          )}
        </div>
      )}
      {detail.value === undefined ? (
        <Skeleton height={24} width={100} />
      ) : (
        <div className="text-xl font-semibold leading-6 text-gray-900">
          {detail.value}
        </div>
      )}
    </div>
  )
}

const renderCards = (
  details: InfoDetail[],
  className?: string,
  parentClassName?: string
) => {
  return details.map((detail, index) => (
    <div key={detail.title ?? index} className={parentClassName}>
      <Card detail={detail} className={className} />
    </div>
  ))
}

const InfoPanel = ({ details }: { details: InfoDetail[] }) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const checkOverflow = () => {
      if (carouselRef.current) {
        setIsOverflowing(
          carouselRef.current.scrollWidth > carouselRef.current.clientWidth
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [details])

  useEffect(() => {
    if (!isOverflowing) return

    const handleScroll = () => {
      if (carouselRef.current) {
        const scrollPosition = carouselRef.current.scrollLeft
        const cardWidth = carouselRef.current.offsetWidth
        const newIndex = Math.round(scrollPosition / cardWidth)
        setActiveIndex(newIndex)
      }
    }

    const carousel = carouselRef.current
    carousel?.addEventListener('scroll', handleScroll)
    return () => carousel?.removeEventListener('scroll', handleScroll)
  }, [isOverflowing])

  const scrollToCard = (index: number) => {
    if (carouselRef.current && isOverflowing) {
      const cardWidth = carouselRef.current.offsetWidth
      const scrollPosition = index * cardWidth
      carouselRef.current.scrollTo({ left: scrollPosition, behavior: 'smooth' })
    }
  }

  const handleDotClick = (index: number) => {
    setActiveIndex(index)
    scrollToCard(index)
  }

  return (
    <div className="mt-4 w-full overflow-visible md:mt-7">
      <div className="overflow-visible">
        <div
          ref={carouselRef}
          className={`-mx-4 flex ${isOverflowing ? 'snap-x snap-mandatory overflow-x-auto' : ''}`}
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {renderCards(
            details,
            'w-full',
            `flex-shrink-0 w-full md:w-fit md:min-w-60 ${isOverflowing ? 'snap-center' : 'mb-[18px]'} px-4`
          )}
        </div>
        {isOverflowing && (
          <div className="mb-2 mt-3 flex justify-center space-x-2">
            {details.map((_, index) => (
              <button
                key={index}
                onClick={() => handleDotClick(index)}
                className={`h-[6px] w-[6px] rounded-full ${
                  index === activeIndex ? 'bg-[#339933]' : 'bg-[#33993380]'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export { InfoPanel }
