import { PaginationV1 } from 'types/api/v1'
import { usePagination } from 'utils/vehicles'

import { CaretLeftIcon } from 'ui/icons'
import { AnalyticsEvent, useAnalytics } from 'utils/use-analytics'

const PaginatorButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <button
      {...props}
      className={`rounded-md border border-[#D0D5DD] px-4 py-2 font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-otr-secondary disabled:cursor-not-allowed disabled:opacity-50 ${
        props.disabled
          ? 'disabled:border-[#D0D5DD]'
          : 'hover:border-otr-primary-dark hover:text-black'
      } ${props.className}`}
    >
      {props.children}
    </button>
  )
}

const Paginator = ({
  pagination,
  showPageNumbers,
}: {
  pagination?: PaginationV1
  showPageNumbers?: boolean
}) => {
  const { trackEvent } = useAnalytics()

  const { totalCount, pageSize, links } = pagination ?? {}
  const { page: pageNumber, setPage, cursor } = usePagination()
  const pageCount =
    totalCount && pageSize ? Math.ceil(totalCount / pageSize) : undefined

  const handlePageChange = (link: string | null, newPage?: number) => {
    if (link) {
      const cursor = new URLSearchParams(link.split('?')[1]).get('cursor') ?? ''
      setPage(cursor, showPageNumbers ? newPage : undefined)
    }
  }

  const onClickFirst = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.currentTarget.blur()

    trackEvent(AnalyticsEvent.SELECT_FIRST_PAGE)
    setPage('', 1)
  }

  const onClickPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.currentTarget.blur()

    trackEvent(AnalyticsEvent.SELECT_PREVIOUS_PAGE)

    if (links?.prev) {
      const newPageNumber = pageNumber === 1 ? 1 : pageNumber - 1
      handlePageChange(links.prev, newPageNumber)
    }
  }

  const onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.currentTarget.blur()

    trackEvent(AnalyticsEvent.SELECT_NEXT_PAGE)

    if (links?.next) {
      handlePageChange(links.next, pageNumber + 1)
    }
  }

  return (
    <div className="font-sm flex flex-row items-center justify-between rounded-b-lg border-t bg-white px-6 py-4 text-sm leading-5 text-[#344054] print:hidden">
      <div aria-label="page number">
        {showPageNumbers && (
          <>
            Page <span className="font-medium">{pageNumber} </span>
            {pageCount && (
              <>
                of <span className="font-medium">{pageCount}</span>
              </>
            )}
          </>
        )}
      </div>

      <div className="flex space-x-2">
        {cursor && (
          <PaginatorButton
            onClick={onClickFirst}
            className="!px-3"
            aria-label="Back to the first page"
          >
            <span className="flex items-center">
              <CaretLeftIcon width="6" height="10" color={'#344054'} />
              <CaretLeftIcon width="6" height="10" color={'#344054'} />
            </span>
          </PaginatorButton>
        )}

        <PaginatorButton
          onClick={onClickPrev}
          disabled={!links?.prev}
          aria-label="Back to the previous page"
        >
          Previous
        </PaginatorButton>
        <PaginatorButton
          onClick={onClickNext}
          disabled={!links?.next}
          aria-label="Go to the next page"
        >
          Next
        </PaginatorButton>
      </div>
    </div>
  )
}

export { Paginator }
