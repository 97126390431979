import React, { useEffect, useState } from 'react'
import { useFindVehicle } from 'utils/vehicles'
import { VehicleV1 } from 'types/api/v1'
import { isEmpty } from 'utils/string-helpers'
import { useFeatureFlag } from 'utils/use-feature-flag'
import clsx from 'clsx'

const VehicleInfoField: React.FC<{
  label: string
  value?: string | null
}> = ({ label, value }) => (
  <div className="py-2">
    <div className="text-sm font-medium text-[#2E2925]">{label}</div>
    <div className="break-words text-sm font-medium text-[#6C757D]">
      {isEmpty(value) ? 'Not Available' : value}
    </div>
  </div>
)

const VehicleInfo: React.FC = () => {
  const query = useFindVehicle()
  const { data, isError } = query
  const vehicleData = data as VehicleV1
  const [vehicle, setVehicle] = useState<VehicleV1>()
  const { value: showAdditionalDetails } = useFeatureFlag(
    'showAdditionalVehicleProperties',
    false
  )

  useEffect(() => {
    if (vehicleData) {
      setVehicle(vehicleData)
    }
  }, [vehicleData])

  if (isError) {
    return <div>Error...</div>
  }

  return (
    <div className="relative">
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="ml-5 flex flex-wrap items-center pt-5">
          <h2 className="font-medium leading-7 text-[#2E2925]">Vehicle Info</h2>
        </div>
      </div>

      <div
        className={
          'mt-4 overflow-auto rounded-b-lg md:max-h-[calc(100vh-395px)]'
        }
      >
        <div
          className={clsx(
            !showAdditionalDetails && 'grid grid-cols-1 bg-gray-50 p-5',

            showAdditionalDetails &&
              'grid grid-cols-1 gap-4 bg-gray-50 p-5 sm:grid-cols-2 lg:grid-cols-3'
          )}
        >
          <VehicleInfoField label="VIN" value={vehicle?.vin} />
          <VehicleInfoField
            label="Manufacturer"
            value={vehicle?.manufacturer}
          />
          <VehicleInfoField label="Model Year" value={vehicle?.modelYear} />
          <VehicleInfoField label="Make" value={vehicle?.make} />
          <VehicleInfoField label="Model" value={vehicle?.model} />
          <VehicleInfoField label="Trim" value={vehicle?.trim} />

          {showAdditionalDetails && (
            <>
              <VehicleInfoField
                label="Engine Manufacturer"
                value={vehicle?.engineManufacturer}
              />
              <VehicleInfoField label="Cab Type" value={vehicle?.bodyCabType} />
              <VehicleInfoField
                label="Displacement (L)"
                value={vehicle?.displacementL}
              />
              <VehicleInfoField
                label="Engine Cylinder Count"
                value={vehicle?.engineCylinders}
              />
              <VehicleInfoField
                label="Drivetrain / Drive Type"
                value={vehicle?.driveType}
              />
              <VehicleInfoField
                label="Engine Model"
                value={vehicle?.engineModel}
              />
              <VehicleInfoField
                label="Primary Fuel Type"
                value={vehicle?.fuelTypePrimary}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { VehicleInfo }
