import {
  useFeatureFlag as useConfigCatFeatureFlag,
  User,
} from 'configcat-react'
import { useAuth } from 'context/auth-context'
import { useOtrAdminControls } from 'features/impersonate/use-otr-admin-controls'

const useFeatureFlag = (flag: string, defaultValue = false) => {
  const { user, isSuperAdmin } = useAuth()
  const { isInternalTester } = useOtrAdminControls()

  let configUser = undefined
  if (user?.identity?.otrUserId && user.identity?.email) {
    const customAttributes =
      isSuperAdmin && isInternalTester
        ? {
            role: 'internal-tester',
          }
        : undefined
    configUser = new User(
      user.identity?.otrUserId,
      user.identity?.email,
      undefined,
      customAttributes
    )
  }

  return useConfigCatFeatureFlag(flag, defaultValue, configUser)
}

export { useFeatureFlag }
