import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css'
import AppLayout from 'ui/app-layout'
import { Vehicles } from 'pages/vehicles'
import { Activate } from 'pages/activate'
import { Vehicle } from 'pages/vehicle'

function AuthenticatedApp() {
  return (
    <AppLayout>
      <Routes>
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/vehicles/:vehicleId" element={<Vehicle />} />
        <Route path="/activate" element={<Activate />} />
        <Route path="*" element={<Navigate to="/vehicles" replace={true} />} />
      </Routes>
    </AppLayout>
  )
}

export { AuthenticatedApp }
