import React, { useEffect, useState } from 'react'
import { recover } from 'utils/auth-service'
import { FloatingLabelInput } from 'ui/floating-label-input'
import { useAsync } from 'utils/use-async'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const ResetPasswordForm = () => {
  const [form, setForm] = useState({ email: '' })
  const [errorMessage, setErrorMessage] = useState('')
  const {
    isLoading,
    isError,
    reset,
    isSuccess,
    run,
  }: {
    isLoading: boolean
    isError: boolean
    reset: () => any
    isSuccess: boolean
    run: any
  } = useAsync()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      reset()
    }
    if (errorMessage) {
      setErrorMessage('')
    }
    setForm({ ...form, [e.target.id]: e.target.value })
  }

  const validate = () => {
    let isValid = true
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!form.email || form.email.length === 0) {
      setErrorMessage('Please enter your email address.')
      isValid = false
    } else if (!emailPattern.test(form.email)) {
      setErrorMessage('Please enter a valid email address.')
      isValid = false
    }

    return isValid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validate()) {
      run(recover(form.email))
    }
  }

  const handResubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    reset()
    run(recover(form.email))
  }

  useEffect(() => {
    if (isError) {
      setErrorMessage('We are unable to send you password reset instructions.')
    } else {
      setErrorMessage('')
    }
  }, [isError])

  return (
    <div className="w-full rounded-lg bg-white p-8 sm:w-2/3 sm:shadow-lg md:w-1/2 lg:w-1/3 2xl:w-1/4">
      {!isSuccess ? (
        <form onSubmit={handleSubmit} noValidate>
          <h1 className="mb-2 text-center text-xl font-semibold">
            Reset Password
          </h1>

          {errorMessage ? (
            <div className="rounded bg-red-500 p-2 text-white">
              {errorMessage}
            </div>
          ) : (
            <div className="h-10"></div>
          )}
          <p className="mb-2 mt-2 text-center">
            We&apos;ll send you instructions to reset your password.
          </p>
          <FloatingLabelInput
            id="email"
            type="email"
            label="Enter your email address"
            autoFocus={true}
            className="mb-10 mt-4"
            onChange={handleChange}
          />
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-2/3 rounded-2xl bg-otr-primary py-2 text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary"
            >
              Send Password Reset Instructions
              {isLoading && (
                <FontAwesomeIcon icon={faSpinner} spin className="ml-2" />
              )}
            </button>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <h1 className="mb-4 text-xl font-semibold">Check your email</h1>
          <p>We have sent password reset instructions to you at: </p>
          <p className="mb-4 font-bold">{form.email}</p>
          <p className="text-sm text-gray-600">
            If you haven&apos;t received an email, check your spam folder or{' '}
            <Link to="/sign-up" className="text-blue-500 hover:underline">
              sign up
            </Link>
          </p>
          <div className="mt-6 flex justify-center space-x-4">
            <Link
              to="/login"
              className="rounded-2xl bg-otr-primary px-4 py-2 text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary"
            >
              Return to Login
            </Link>
            <button
              className="rounded-2xl bg-gray-200 px-4 py-2 text-gray-700 shadow-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
              onClick={handResubmit}
            >
              Resend
              {isLoading && (
                <FontAwesomeIcon icon={faSpinner} spin className="ml-2" />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export { ResetPasswordForm }
