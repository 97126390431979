import {
  createAccount,
  CreateUserAccount,
  CreateUserAccountResult,
} from 'utils/auth-service'
import { useEffect, useState } from 'react'
import { FloatingLabelInput } from 'ui/floating-label-input'
import { useAsync } from 'utils/use-async'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/min'
import type { Value, Country } from 'react-phone-number-input/min'
import 'react-phone-number-input/style.css'

interface CreateUserForm extends CreateUserAccount {
  passwordConfirmation?: string | null
}

function SignUpForm() {
  const [form, setForm] = useState<CreateUserForm>({})
  const {
    isError,
    run,
    error,
    data,
    reset,
    isLoading,
  }: {
    run: any
    error: CreateUserAccountResult | null
    data: CreateUserAccountResult | null
    isError: boolean
    reset: any
    isLoading: boolean
  } = useAsync()
  const [errorMessage, setErrorMessage] = useState('')
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [showCountrySelect, setShowCountrySelect] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country>('US')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      reset()
    }
    if (errorMessage) {
      setErrorMessage('')
    }
    setForm({ ...form, [e.target.id]: e.target.value })
  }

  const handlePhoneChange = (value: Value, country?: Country) => {
    if (isError) {
      reset()
    }
    if (errorMessage) {
      setErrorMessage('')
    }
    if (country) {
      setSelectedCountry(country)
    }

    setForm({ ...form, phone: value as string })
  }

  const validate = () => {
    let isValid = true
    if (!form.password || form.password.length === 0) {
      setErrorMessage('Password is required.')
      isValid = false
    } else if (
      !form.passwordConfirmation ||
      form.passwordConfirmation.length === 0
    ) {
      setErrorMessage('Password confirmation is required.')
      isValid = false
    } else if (form.password !== form.passwordConfirmation) {
      setErrorMessage('Passwords do not match.')
      isValid = false
    } else if (!form.phone || form.phone.length === 0) {
      setErrorMessage('Phone number is required.')
      isValid = false
    } else if (!isValidPhoneNumber(form.phone, selectedCountry)) {
      setErrorMessage('Please enter a valid phone number.')
      isValid = false
    }
    return isValid
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validate()) run(createAccount(form as CreateUserAccount))
  }

  useEffect(() => {
    if (error?.errors && error.errors.length > 0) {
      setErrorMessage(error.errors[0])
    } else {
      setErrorMessage('')
    }
  }, [error])

  const accountCreated = data?.success

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit} className="" noValidate>
        <h1 className=" mb-4 text-center text-xl font-semibold">
          Activate your adapter and create an account!
        </h1>
        {errorMessage ? (
          <div className="rounded bg-red-500 p-2 text-white">
            {errorMessage}
          </div>
        ) : (
          <div className="h-10"></div>
        )}
        <FloatingLabelInput
          id="activationCode"
          type="text"
          label="Enter your activation code"
          autoFocus={true}
          className=" mt-4"
          onChange={handleChange}
        />
        <div className="mx-8 mb-6 mt-6 border-b border-stone-200"></div>

        <FloatingLabelInput
          id="firstName"
          type="text"
          label="Enter your first name"
          className="mb-4"
          onChange={handleChange}
        />
        <FloatingLabelInput
          id="lastName"
          type="text"
          label="Enter your last name"
          className=""
          onChange={handleChange}
        />
        <div className="mx-8 mb-6 mt-6 border-b border-stone-200"></div>
        <FloatingLabelInput
          id="email"
          type="email"
          label="Enter your email address"
          className="mb-4 mt-4"
          onChange={handleChange}
        />

        <div className="relative">
          <div className="[&_.PhoneInput]:flex [&_.PhoneInput]:h-10 [&_.PhoneInput]:items-center">
            <PhoneInput
              aria-label="Enter your phone number"
              international
              countryCallingCodeEditable={false}
              defaultCountry={showCountrySelect ? 'US' : undefined}
              value={form.phone || undefined}
              onChange={handlePhoneChange}
              onCountryChange={(country: Country | undefined) =>
                country && setSelectedCountry(country)
              }
              onFocus={() => {
                setIsPhoneFocused(true)
                setShowCountrySelect(true)
              }}
              onBlur={(e) => {
                const relatedTarget = e.relatedTarget as HTMLElement
                const isCountrySelect =
                  relatedTarget?.closest('.PhoneInputCountry')

                setIsPhoneFocused(false)
                if (!form.phone && !isCountrySelect) {
                  setShowCountrySelect(false)
                }
              }}
              className={`[&_.PhoneInputCountry]:absolute [&_.PhoneInputCountry]:left-2 [&_.PhoneInputCountry]:top-1/2 [&_.PhoneInputCountry]:z-10 [&_.PhoneInputCountry]:-translate-y-1/2 [&_.PhoneInputInput]:h-10 [&_.PhoneInputInput]:w-full [&_.PhoneInputInput]:rounded [&_.PhoneInputInput]:border-2 [&_.PhoneInputInput]:px-2 [&_.PhoneInputInput]:pl-14 [&_.PhoneInputInput]:text-lg [&_.PhoneInputInput]:outline-none [&_.PhoneInputInput]:placeholder:text-gray-500 [&_.PhoneInputInput]:focus:border-otr-primary ${!isPhoneFocused && !form.phone && !showCountrySelect ? '[&_.PhoneInputCountry]:hidden [&_.PhoneInputInput]:pl-2' : ''}`}
              placeholder="Enter your phone number"
            />
          </div>
          <label
            className={`pointer-events-none absolute -top-2 left-2 bg-white px-1 text-xs text-gray-500 ${!isPhoneFocused && !form.phone ? 'invisible' : ''}`}
          >
            Enter your phone number
          </label>
        </div>
        <div className="mx-8 mb-6 mt-6 border-b border-stone-200"></div>

        <FloatingLabelInput
          id="password"
          type="password"
          label="Enter your password"
          className="mb-2"
          onChange={handleChange}
        />
        <FloatingLabelInput
          id="passwordConfirmation"
          type="password"
          label="Confirm your password"
          className="mb-8"
          onChange={handleChange}
        />
        <div className="flex justify-center">
          <button
            type="submit"
            className="w-2/3 rounded-2xl bg-otr-primary py-2 text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary"
          >
            Activate
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="ml-2" />
            )}
          </button>
        </div>
        <div className="mt-6 text-center">
          <Link to="/activate" className="text-blue-500 hover:underline">
            I already have an account
          </Link>
        </div>
      </form>
    )
  }

  return (
    <div className="w-full rounded-lg bg-white p-8 sm:w-2/3 sm:shadow-lg md:w-1/2 lg:w-1/3 2xl:w-1/4">
      {accountCreated ? (
        <div className="text-center">
          <div className="text-xl font-bold">
            Congratulations your adapter has been activated and your account has
            been created!{' '}
          </div>
          <div className="mt-6">
            <Link to="/" className=" text-blue-500 hover:underline">
              Login
            </Link>
            {''} to continue.
          </div>
        </div>
      ) : (
        renderForm()
      )}
    </div>
  )
}

export { SignUpForm }
