import { Header } from './header'

import { ReactNode } from 'react'
import { Navigation } from './navigation'
import { useBreakpoint } from 'utils/use-breakpoint'
import { OtrAdminControls } from 'features/impersonate/otr-admin-controls'
import { useAuth } from 'context/auth-context'

function AppLayout({ children }: { children: ReactNode }) {
  const { isFullView } = useBreakpoint()
  const { isSuperAdmin } = useAuth()
  return (
    <div className="md:flex">
      {isFullView && <Navigation />}
      <main className="  isolate h-screen w-full md:flex ">
        {!isFullView && <Header />}
        {isSuperAdmin && <OtrAdminControls />}

        {children}
      </main>
    </div>
  )
}

export default AppLayout
