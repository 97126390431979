type Mode = 'development' | 'production' | 'test'
interface AppConfig {
  api: {
    baseUrl: string
    mockEnabled: boolean
  }
  intercom: {
    appId: string
    enabled: boolean
  }
  hotjar: {
    siteId: number
    version: number
    enabled: boolean
  }
  mode: Mode
  prod: boolean
  dev: boolean
  test: boolean
  configCat: {
    sdkKey: string
    enabled: boolean
  }
  firebase: {
    enabled: boolean
  }
}

const appConfig: AppConfig = {
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL as string,
    mockEnabled: import.meta.env.VITE_API_MOCK_ENABLED === 'true',
  },
  intercom: {
    appId: import.meta.env.VITE_INTERCOM_APP_ID as string,
    enabled: import.meta.env.VITE_INTERCOM_ENABLED === 'true',
  },
  hotjar: {
    siteId: import.meta.env.VITE_HOTJAR_SITE_ID as number,
    version: import.meta.env.VITE_HOTJAR_VERSION as number,
    enabled: import.meta.env.VITE_HOTJAR_ENABLED === 'true',
  },
  mode: import.meta.env.MODE as Mode,
  prod:
    import.meta.env.MODE === 'production' || import.meta.env.MODE === 'prod',
  dev: import.meta.env.MODE === 'development' || import.meta.env.MODE === 'dev',
  test: import.meta.env.MODE === 'test',
  configCat: {
    sdkKey: import.meta.env.VITE_CONFIGCAT_SDK_KEY as string,
    enabled: import.meta.env.VITE_CONFIGCAT_ENABLED === 'true',
  },
  firebase: {
    enabled: import.meta.env.VITE_FIREBASE_ENABLED === 'true',
  },
}

export { appConfig }
