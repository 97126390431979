import {
  faCircleChevronUp,
  faClose,
  faEllipsisVertical,
  faGripVertical,
  faMasksTheater,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useNavigate } from 'react-router-dom'
import { useBreakpoint } from 'utils/use-breakpoint'
import React from 'react'
import { useOtrAdminControls } from './use-otr-admin-controls'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

function OtrAdminControls() {
  const navigate = useNavigate()
  const draggableRef = useRef(null)
  const { isFullView } = useBreakpoint()

  const {
    impersonatedEmail,
    impersonate,
    stopImpersonating,
    isInternalTester,
    toggleInternalTester,
  } = useOtrAdminControls()

  const [searchInputValue, setSearchInputValue] = useState(
    impersonatedEmail ?? ''
  )
  const [isDragging, setIsDragging] = useState(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchInputValue(value)
  }

  const handleImpersonate = (event: React.FormEvent) => {
    event.preventDefault()
    impersonate(searchInputValue)
  }

  const isImpersonateEnabled =
    searchInputValue.length >= 4 && emailRegex.test(searchInputValue)

  const clearSearchInput = () => {
    setSearchInputValue('')
    stopImpersonating()
    navigate('/vehicles')
  }

  const defaultPosition = isFullView ? { x: 10, y: 200 } : { x: 100, y: 80 }

  const toggleDragging = () => {
    setIsDragging(!isDragging)
  }

  const [isFocused, setIsFocused] = useState(false)
  const [isEditing, setIsEditing] = useState(!impersonatedEmail)

  const onClickEdit = () => {
    setIsEditing(!isEditing)
  }

  const viewingAs = impersonatedEmail && (
    <div className="text-center text-white">
      Viewing as{' '}
      <span className="break-words font-bold">{impersonatedEmail} </span>
    </div>
  )

  const onClickToggle = (event: React.MouseEvent) => {
    event.preventDefault()
    toggleInternalTester()
  }

  return (
    <Draggable
      defaultPosition={defaultPosition}
      cancel=".no-drag"
      nodeRef={draggableRef}
      handle=".drag-handle"
      onStart={toggleDragging}
      onStop={toggleDragging}
    >
      <div
        className="fixed inset-0 z-30 h-fit w-fit text-sm"
        ref={draggableRef}
      >
        <div className=" rounded-lg bg-black pt-2 text-center shadow-xl">
          <div
            className={` flex flex-row items-center gap-3 px-2 pb-2 ${impersonatedEmail ? 'space-between justify-between' : 'justify-center'}`}
          >
            <button
              className={`drag-handle ${!impersonatedEmail && 'absolute left-2 top-2'} text-white ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
            >
              <FontAwesomeIcon icon={faGripVertical} />
            </button>
            {isEditing ? (
              <>
                <div className=" font-bold text-white">OTR Admin Settings</div>
              </>
            ) : (
              <>
                <div className="w-48">{viewingAs}</div>
              </>
            )}
            {(!isEditing || impersonatedEmail) && (
              <button
                className={`rounded-lg  focus:outline-none focus:ring-2 focus:ring-otr-secondary disabled:cursor-not-allowed disabled:opacity-50 ${isEditing ? 'text-white' : 'bg-otr-primary px-2 py-1 text-white shadow-lg hover:text-otr-primary-dark'}`}
                onClick={onClickEdit}
                title={isEditing ? 'Collapse' : 'Edit OTR Admin Settings'}
              >
                <FontAwesomeIcon
                  icon={isEditing ? faCircleChevronUp : faEllipsisVertical}
                  size="lg"
                />
              </button>
            )}
          </div>
          <div className="px-2">
            {isEditing && (
              <form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault()
                }}
              >
                {viewingAs ? (
                  <div className="mt-4 flex flex-row">
                    {viewingAs}
                    <button
                      className="ml-2 rounded bg-otr-primary px-1 text-sm text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary disabled:cursor-not-allowed disabled:opacity-50"
                      type="submit"
                      title="Clear Impersonation"
                      onClick={clearSearchInput}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </button>
                  </div>
                ) : (
                  <div className="mt-4 flex flex-row">
                    <div className={`relative mr-1 w-full`}>
                      <label
                        htmlFor="impersonate-email"
                        className={`absolute -top-2 left-2 rounded-lg bg-black px-1 transition-all ${isFocused ? 'text-xs' : 'invisible'} text-white`}
                      >
                        Enter user email to impersonate
                      </label>
                      <input
                        type="text"
                        id="impersonate-email"
                        className="h-2 w-60 rounded border-2 bg-black px-2 py-4 text-white outline-none focus:border-otr-primary"
                        placeholder={
                          isFocused ? '' : 'Enter user email to impersonate'
                        }
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        autoFocus={true}
                        onChange={handleInputChange}
                        value={searchInputValue}
                      />
                    </div>
                    <button
                      className="no-drag rounded bg-otr-primary p-2 text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary disabled:cursor-not-allowed disabled:opacity-50"
                      type="submit"
                      disabled={!isImpersonateEnabled}
                      title="Impersonate"
                      onClick={handleImpersonate}
                    >
                      <FontAwesomeIcon icon={faMasksTheater} />
                    </button>
                  </div>
                )}
                <div className="mt-4 flex flex-row pb-4 text-white">
                  <label>
                    Preview unreleased features
                    <button
                      onClick={onClickToggle}
                      className="ml-2 transform transition-transform duration-200"
                    >
                      <FontAwesomeIcon
                        icon={isInternalTester ? faToggleOn : faToggleOff}
                        className={` ${
                          isInternalTester
                            ? 'text-otr-primary'
                            : 'bg-whit text-gray-500'
                        }`}
                        size="2xl"
                      />
                    </button>
                  </label>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Draggable>
  )
}

export { OtrAdminControls }
