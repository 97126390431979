import { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { FaultCodeViewModel } from 'types/fault-codes'
import clsx from 'clsx'
import { Badge, BadgeLevel } from 'ui/badge'
import { Tooltip } from 'ui/tooltip'
import { useFeatureFlag } from 'utils/use-feature-flag'
import { AnalyticsEvent, useAnalytics } from 'utils/use-analytics'

const Title = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={`pb-1 text-sm font-medium leading-5 ${className}`}>
      {children}
    </div>
  )
}

function FaultCodeCard({
  faultCode,
  onClick,
  className,
}: {
  faultCode?: FaultCodeViewModel
  onClick?: () => void
  className?: string
}) {
  const { trackEvent } = useAnalytics()

  const [showTooltip, setShowTooltip] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const overviewRef = useRef<HTMLDivElement | null>(null)

  const { value: isOverviewToolTipEnabled } = useFeatureFlag(
    'faultCodeOverviewTooltip',
    false
  )

  useEffect(() => {
    if (overviewRef.current) {
      const isTextTruncated =
        overviewRef.current.scrollWidth > overviewRef.current.offsetWidth
      setIsTruncated(isTextTruncated)
    }
  }, [isOverviewToolTipEnabled])

  const handleMouseEnter = () => {
    if (isTruncated) {
      setShowTooltip(true)
    }
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const cardClasses = clsx(
    'border-t border-[#EAECF0] w-full py-[14px] px-4 cursor-pointer hover:bg-[#F0F2F4] hover:shadow-md grid grid-cols-[2fr_2fr_1fr_1fr] lg:grid-cols-4 gap-4transition-colors duration-300 ease-in-out whitespace-nowrap print:whitespace-normal',
    className
  )

  const handleClick = () => {
    trackEvent(AnalyticsEvent.SELECT_FAULT_CODE)
    onClick?.()
  }

  return (
    <div className={cardClasses} onClick={handleClick}>
      <div className="flex flex-col justify-center">
        {faultCode?.label === undefined ? (
          <Skeleton height={12} width={200} />
        ) : (
          <>
            <Title className="text-[#6C757D]">Fault Code</Title>
            <div className="text-sm font-medium leading-5 text-[#2E2925]">
              {faultCode.label}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col justify-center overflow-hidden pr-6">
        {faultCode?.name === undefined ? (
          <Skeleton height={12} width={150} />
        ) : (
          <>
            <Title className="text-[#6C757D]">Overview</Title>

            {isOverviewToolTipEnabled ? (
              <div
                className="relative truncate text-sm print:whitespace-normal"
                ref={overviewRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {faultCode?.name !== null ? (
                  <span>{faultCode.name}</span>
                ) : (
                  <span>N/A</span>
                )}

                <Tooltip
                  content={
                    <div className="max-w-96 break-words p-3 text-xs font-medium leading-[18px] text-[#101828]">
                      {faultCode?.name}
                    </div>
                  }
                  isVisible={showTooltip}
                  anchorEl={overviewRef.current}
                />
              </div>
            ) : (
              <div className="truncate text-sm print:whitespace-normal">
                {faultCode?.name !== null ? (
                  <span>{faultCode.name}</span>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex flex-col justify-center">
        {faultCode?.isActive === undefined ? (
          <Skeleton height={12} width={40} />
        ) : (
          <>
            <Title className="text-[#2E2925]">Status</Title>
            <div className="text-sm">
              <Badge
                level={
                  faultCode?.isActive ? BadgeLevel.WARNING : BadgeLevel.INFO
                }
              >
                {faultCode?.isActive ? 'Active' : 'Inactive'}
              </Badge>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col justify-center">
        {faultCode?.startedAt === undefined ? (
          <Skeleton height={12} width={110} />
        ) : (
          <>
            <Title className="text-[#2E2925]">Date</Title>
            <div className="text-sm">{faultCode?.startedAt}</div>
          </>
        )}
      </div>
    </div>
  )
}

export { FaultCodeCard }
