import {
  CommandResultV1,
  CommandResultV1PaginatedResponseV1,
} from 'types/api/v1'
import {
  getCommandLabel,
  useFindCommands,
  usePagination,
  useFindVehicle,
} from 'utils/vehicles'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatDateString } from 'utils/date-helpers'
import { NotFoundPlaceholder } from './not-found-placeholder'
import { isEmpty } from 'utils/string-helpers'
import { Badge, BadgeLevel } from 'ui/badge'
import clsx from 'clsx'
import { Paginator } from './paginator'
import { useVehiclePrint } from './use-vehicle-print'
import { Scrollable } from 'ui/scrollable'
import { useFeatureFlag } from 'utils/use-feature-flag'

export class CommandViewModel {
  public id?: string
  public commandLabel?: string
  public startedAt?: string
  public endedAt?: string

  constructor(command?: CommandResultV1) {
    if (command) {
      this.id = command.commandId ?? Math.random().toString(36).slice(2, 11)
      this.commandLabel = getCommandLabel(command)
      this.startedAt = command.startedAt
        ? formatDateString(command.startedAt)
        : 'Unknown'
      this.endedAt = command.endedAt
        ? formatDateString(command.endedAt)
        : 'Unknown'
    } else {
      this.id = Math.random().toString(36).substr(2, 9)
    }
  }
}

function CommandRow({
  command,
  className,
}: {
  command: CommandViewModel
  className?: string
}) {
  const classes = clsx(
    'grid grid-cols-2 border-t  border-[#EAECF0] text-sm font-medium leading-5 text-[#2E2925]',
    className
  )
  return (
    <div className={classes} role="row">
      <div className="py-6 pl-5">
        {command.commandLabel ? (
          <span className="">{command.commandLabel}</span>
        ) : (
          <Skeleton width={160} />
        )}
      </div>
      <div className="py-6 pl-5">
        {command.startedAt ? command.startedAt : <Skeleton width={140} />}
      </div>
    </div>
  )
}

function Commands() {
  const { vin } = useFindVehicle()
  const { cursor, clearPage } = usePagination()

  const displayCount = 10

  const { PrintButton, PrintWrapper } = useVehiclePrint(
    `commands-history-${vin}`
  )

  const { data, isLoading, isError } = useFindCommands(
    vin,
    cursor,
    displayCount
  )

  const commandResponse = data as CommandResultV1PaginatedResponseV1
  const [commands, setCommands] = useState<CommandViewModel[]>(
    Array(displayCount)
      .fill(null)
      .map(() => new CommandViewModel())
  )

  const { value: canAccessVehicleInfo } = useFeatureFlag(
    'vehicleDetailInfoTab',
    false
  )

  useEffect(() => {
    if (commandResponse?.data) {
      const newCommands = commandResponse.data.map(
        (command) => new CommandViewModel(command)
      )
      if (JSON.stringify(newCommands) !== JSON.stringify(commands)) {
        if (isEmpty(commandResponse.pagination?.links?.prev)) {
          clearPage()
        }
        setCommands(newCommands)
      }
    }
  }, [clearPage, commandResponse, commands])

  if (isError) {
    return <div>Error...</div>
  }

  return (
    <PrintWrapper vin={vin}>
      <div className="relative">
        <div className="flex  flex-col justify-between lg:flex-row">
          <div
            className={clsx(
              'ml-5 flex flex-wrap items-center',
              canAccessVehicleInfo && 'pt-5 lg:pt-2',
              !canAccessVehicleInfo && 'pt-5'
            )}
          >
            <h2 className="font-medium leading-7 text-[#2E2925]">Commands</h2>
            <Badge level={BadgeLevel.PRIMARY} className="ml-2">
              {commandResponse?.pagination?.totalCount ?? ''}
            </Badge>
          </div>
          <div
            className={clsx(
              'mb-2 mt-6 lg:mb-0 print:hidden',
              !canAccessVehicleInfo &&
                'mx-4 lg:relative lg:bottom-[54px] lg:right-8 lg:mx-0 lg:mr-0 lg:mt-0',
              canAccessVehicleInfo &&
                'mr-4 flex items-center pl-4 lg:mt-2 lg:pl-0'
            )}
          >
            <PrintButton className="w-full lg:w-auto" />
          </div>
        </div>
      </div>
      <Scrollable
        className={`mt-4 overflow-auto rounded-b-lg md:max-h-[calc(100vh-544px)] lg:max-h-[calc(100vh-464px)]  print:max-h-full print:overflow-visible`}
      >
        <div className="sticky top-0 grid grid-cols-2 border-b border-t border-[#EAECF0] bg-white text-[0.95rem] text-xs font-medium leading-[18px] text-[#6C757D]">
          <div className="py-[23px] pl-6">Commands</div>
          <div className="py-[23px] pl-6">Ran</div>
        </div>
        <div className=" rounded-b-lg print:max-h-full print:overflow-visible">
          {commands.length === 0 ? (
            <NotFoundPlaceholder
              className="h-96"
              description={'No Commands Found'}
            />
          ) : (
            commands.map((command, index) => {
              const classes = clsx(
                isLoading && command.startedAt
                  ? 'bg-[#F0F2F4]'
                  : index % 2 === 0
                    ? 'bg-gray-50'
                    : 'bg-white',
                index === 0 ? 'border-t-0' : ''
              )
              return (
                <CommandRow
                  key={command.id}
                  command={command}
                  className={classes}
                />
              )
            })
          )}
        </div>
      </Scrollable>
      <Paginator pagination={commandResponse?.pagination} />
    </PrintWrapper>
  )
}

export { Commands }
