import { VehiclesPaginatedResponseV1 } from 'types/api/v1'
import { useFindVehicles } from 'utils/vehicles'
import { InfoDetail, InfoPanel } from './info-panel'
import { useEffect, useState } from 'react'

const VehiclesInfoPanel = () => {
  const { data } = useFindVehicles(10)
  const vehiclesResponse = data as VehiclesPaginatedResponseV1

  const [details, setDetails] = useState<InfoDetail[]>([{}, {}])

  useEffect(() => {
    if (vehiclesResponse) {
      setDetails([
        {
          title: 'Fault Codes Logged',
          value: vehiclesResponse?.faultCodesLoggedCount?.toString() ?? '--',
          tooltip:
            'This is the total number of fault codes currently logged in our system, covering all vehicles in the fleet.',
        },
        {
          title: 'Commands Logged',
          value: vehiclesResponse?.commandsLoggedCount?.toString() ?? '--',
          tooltip:
            'This is the total number of bi-directional commands sent and logged in our system, covering all vehicles in the fleet.',
        },
      ])
    }
  }, [vehiclesResponse])

  return <InfoPanel details={details} />
}

export { VehiclesInfoPanel }
