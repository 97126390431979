import React, { useState } from 'react'

const OtrAdminControlsContext = React.createContext(null)
OtrAdminControlsContext.displayName = 'OtrAdminControls'

const IMPERSONATE_EMAIL_KEY = 'impersonate_email'
const INTERNAL_TESTER_KEY = 'internal_tester'

function OtrAdminControlsProvider(props: any) {
  const [impersonatedEmail, setImpersonatedEmail] = useState<string | null>(
    window.localStorage.getItem(IMPERSONATE_EMAIL_KEY)
  )
  const [isInternalTester, setIsInternalTester] = useState<boolean>(() => {
    const storedValue = window.localStorage.getItem(INTERNAL_TESTER_KEY)
    return storedValue === 'true'
  })

  const impersonate = (email: string) => {
    setImpersonatedEmail(email)
    window.localStorage.setItem(IMPERSONATE_EMAIL_KEY, email)
  }

  const stopImpersonating = () => {
    setImpersonatedEmail(null)
    window.localStorage.removeItem(IMPERSONATE_EMAIL_KEY)
  }

  const toggleInternalTester = () => {
    const newValue = !isInternalTester
    setIsInternalTester(newValue)
    window.localStorage.setItem(INTERNAL_TESTER_KEY, JSON.stringify(newValue))
  }

  const flush = () => {
    stopImpersonating()
    setIsInternalTester(false)
    window.localStorage.removeItem(INTERNAL_TESTER_KEY)
  }

  const value: OtrAdminControlsData = {
    impersonatedEmail,
    impersonate,
    stopImpersonating,
    isInternalTester,
    toggleInternalTester,
    flush,
  }

  return <OtrAdminControlsContext.Provider value={value} {...props} />
}

export interface OtrAdminControlsData {
  impersonatedEmail: string | null
  impersonate: (email: string) => void
  stopImpersonating: () => void
  isInternalTester: boolean
  toggleInternalTester: () => void
  flush: () => void
}

export { OtrAdminControlsProvider, OtrAdminControlsContext }
