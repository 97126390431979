import { MouseEventHandler } from 'react'

const TableHeaderCell = ({
  children,
  className = '',
  ...props
}: {
  children: React.ReactNode
  className?: string
  props?: React.HTMLProps<HTMLDivElement>
}) => {
  return (
    <div
      className={`table-cell border-b border-stone-200 p-[22px] ${className}`}
      {...props}
    >
      <div className="flex flex-row items-center">{children}</div>
    </div>
  )
}

const TableRow = ({
  children,
  className = '',
  onSelect,
  ...props
}: {
  children: React.ReactNode
  className?: string
  onSelect?: MouseEventHandler<HTMLDivElement>
  props?: React.HTMLProps<HTMLDivElement>
}) => {
  return (
    <div
      className={`table-row transition-colors duration-300  ${onSelect ? 'cursor-pointer hover:bg-[#F0F2F4] hover:shadow-md ' : ''} ${className}`}
      onClick={
        onSelect
          ? onSelect
          : () => {
              return null
            }
      }
      {...props}
    >
      {children}
    </div>
  )
}

const TableCell = ({
  children,
  className = '',
  ...props
}: {
  children: React.ReactNode
  className?: string
  props?: React.HTMLProps<HTMLDivElement>
}) => {
  return (
    <div className={`table-cell p-[26px] ${className}`} {...props}>
      {children}
    </div>
  )
}

export { TableHeaderCell, TableRow, TableCell }
