import { useVehicleSearch, VehicleDetailTab } from 'utils/vehicles'
import { Commands } from './commands'
import { FaultCodesHistory } from './fault-codes/fault-codes-history'
import { FaultCodesLatest } from './fault-codes/fault-codes-latest'
import { VehicleInfo } from './vehicle-info'
import { isEmpty } from 'utils/string-helpers'
import { Scrollable } from 'ui/scrollable'
import { useFeatureFlag } from 'utils/use-feature-flag'

const Tab = ({
  isSelected,
  handleClick,
  textPositionClassName = 'left-0 right-4',
  className,
  children,
  buttonProps,
}: {
  isSelected: boolean
  handleClick: () => void
  textPositionClassName?: string
  className?: string
  children: React.ReactNode
  buttonProps?: React.ComponentProps<'button'>
}) => {
  return (
    <button
      className={`relative pb-5 text-base font-medium ${
        isSelected ? 'text-[#339933]' : 'text-[#6C757D]'
      } ${className}`}
      onClick={handleClick}
      role="tab"
      aria-selected={isSelected}
      {...buttonProps}
    >
      {children}
      {isSelected && (
        <span
          className={`absolute bottom-0 z-10 h-0.5 bg-[#339933] ${textPositionClassName}`}
        ></span>
      )}
      <span className="absolute bottom-0 left-0 right-0 h-[1px] bg-[#EAECF0]"></span>
    </button>
  )
}

function VehicleDetail() {
  const { tab, selectTab } = useVehicleSearch()
  const { value: canAccessVehicleInfo } = useFeatureFlag(
    'vehicleDetailInfoTab',
    false
  )

  const { value: latestFaultCodesTabEnabled } = useFeatureFlag(
    'latestFaultCodes',
    false
  )

  let selectedTab = tab
  const allowedTabs = Object.values(VehicleDetailTab).filter(
    (tab) =>
      tab !== VehicleDetailTab.FAULT_CODES_LATEST || latestFaultCodesTabEnabled
  )

  if (isEmpty(tab) || !allowedTabs.includes(tab)) {
    selectedTab = latestFaultCodesTabEnabled
      ? VehicleDetailTab.FAULT_CODES_LATEST
      : VehicleDetailTab.FAULT_CODES_HISTORY
  }

  return (
    <div className="mb-8 rounded-lg border border-gray-200 bg-white shadow-2  md:mb-0 md:max-w-[calc(100vw-346px)]">
      <div className="">
        <Scrollable
          className="ml-6 mr-4 mt-7 flex overflow-x-auto whitespace-nowrap text-sm sm:text-base"
          type="thin"
        >
          {latestFaultCodesTabEnabled && (
            <Tab
              isSelected={selectedTab === VehicleDetailTab.FAULT_CODES_LATEST}
              handleClick={() => selectTab(VehicleDetailTab.FAULT_CODES_LATEST)}
              className="pl-1 pr-5"
              aria-label="Latest Fault Codes"
            >
              Latest Fault Codes
            </Tab>
          )}
          <Tab
            isSelected={selectedTab === VehicleDetailTab.FAULT_CODES_HISTORY}
            handleClick={() => selectTab(VehicleDetailTab.FAULT_CODES_HISTORY)}
            className="pl-1 pr-5"
            aria-label="Fault Codes History"
          >
            Fault Codes History
          </Tab>
          <Tab
            isSelected={selectedTab === VehicleDetailTab.COMMANDS_HISTORY}
            handleClick={() => selectTab(VehicleDetailTab.COMMANDS_HISTORY)}
            className="px-1"
            textPositionClassName="left-0 right-0"
            aria-label="Commands History"
          >
            Commands History
          </Tab>
          {canAccessVehicleInfo && (
            <Tab
              isSelected={selectedTab === VehicleDetailTab.VEHICLE_INFO}
              handleClick={() => selectTab(VehicleDetailTab.VEHICLE_INFO)}
              className="px-4"
              textPositionClassName="left-0 right-0"
              aria-label="Vehicle Info"
            >
              Vehicle Info
            </Tab>
          )}
        </Scrollable>
        <div className="mt-[6px] rounded-b-lg">
          {selectedTab === VehicleDetailTab.FAULT_CODES_LATEST ? (
            <FaultCodesLatest />
          ) : selectedTab === VehicleDetailTab.FAULT_CODES_HISTORY ? (
            <FaultCodesHistory />
          ) : selectedTab === VehicleDetailTab.VEHICLE_INFO &&
            canAccessVehicleInfo ? (
            <VehicleInfo />
          ) : selectedTab === VehicleDetailTab.COMMANDS_HISTORY ? (
            <Commands />
          ) : (
            <FaultCodesHistory />
          )}
        </div>
      </div>
    </div>
  )
}

export { VehicleDetail }
