import { useEffect } from 'react'
import { useAuth } from 'context/auth-context'
import { useAsync } from 'utils/use-async'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FloatingLabelInput } from 'ui/floating-label-input'
import { Link } from 'react-router-dom'

const LoginForm = () => {
  const { login, error: authContextError } = useAuth()
  const { isLoading, isError, error, run, reset, setError } = useAsync()

  useEffect(() => {
    if (authContextError) {
      setError(authContextError)
    }
  }, [authContextError, setError])

  function handleLogin(event: any) {
    event.preventDefault()
    const { email, password } = event.target.elements
    run(login({ email: email.value, password: password.value }))
  }

  const onChange = () => {
    if (isError) {
      reset()
    }
  }

  const errorMessage = isError
    ? typeof error === 'object' && error !== null
      ? error?.errorMessage ?? error.message
      : error
    : ''

  return (
    <form onSubmit={handleLogin} className="" noValidate>
      <h1 className="mb-4 text-center text-2xl font-semibold">Login</h1>
      {errorMessage ? (
        <div className="rounded bg-red-500 p-2 text-white">{errorMessage}</div>
      ) : (
        <div className="h-10"></div>
      )}
      <FloatingLabelInput
        id="email"
        type="email"
        label="Enter your email address"
        autoFocus={true}
        className="mb-4 mt-4"
        onChange={onChange}
      />
      <FloatingLabelInput
        id="password"
        type="password"
        label="Enter your password"
        className="mb-8"
        onChange={onChange}
      />
      <div className="flex justify-center">
        <button
          type="submit"
          className="w-2/3 rounded-2xl bg-otr-primary py-2 text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary"
        >
          Login
          {isLoading && (
            <FontAwesomeIcon icon={faSpinner} spin className="ml-2" />
          )}
        </button>
      </div>
      <div className="mt-10 text-center text-sm leading-5 text-[#475467]">
        <div>
          Don&apos;t have an account yet?
          <Link to="/sign-up" className="ml-1 text-blue-500 hover:underline">
            Sign up
          </Link>
        </div>
        <div className="mt-2">
          Can&apos;t login?
          <a
            href="https://help.otrperformance.com/en/"
            className="ml-1 text-blue-500 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Visit our help center
          </a>
        </div>
        <div className="mt-2">
          <Link to="/forgot-password" className="text-blue-500 hover:underline">
            Forgot Password?
          </Link>
        </div>
      </div>
    </form>
  )
}

export { LoginForm }
