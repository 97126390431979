import clsx from 'clsx'

const Scrollable = ({
  className,
  children,
  type = 'base',
}: {
  className?: string
  children: React.ReactNode
  type?: 'thin' | 'base' | 'thick'
}) => {
  const classes = clsx(
    'scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-400 scrollbar-track-gray-200',
    type === 'base'
      ? 'scrollbar-w-[6px] scrollbar-h-[6px]'
      : type === 'thin'
        ? 'scrollbar-w-1 scrollbar-h-1'
        : 'scrollbar-w-2 scrollbar-h-2',
    className
  )
  return <div className={classes}>{children}</div>
}

export { Scrollable }
