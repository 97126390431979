import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { appConfig } from './app-config'
import Hotjar from '@hotjar/browser'
import { initializeApp } from 'firebase/app'
import { Analytics, getAnalytics, setUserId } from 'firebase/analytics'
import TagManager from 'react-gtm-module'

// Firebase
// firebase considers all these values as public
const isFirebaseEnabled = appConfig.firebase.enabled
let analytics: Analytics | null = null

const defaultTagManagerArgs = {
  gtmId: 'GTM-W65SCLLD',
}

const initFirebase = () => {
  if (isFirebaseEnabled) {
    const firebaseConfig = {
      apiKey: 'AIzaSyCzryEYk2ZfVkAhxGA6ewk9NpJz2ZU5_dI',
      authDomain: 'otr-cloud.firebaseapp.com',
      projectId: 'otr-cloud',
      storageBucket: 'otr-cloud.firebasestorage.app',
      messagingSenderId: '16699300635',
      appId: '1:16699300635:web:0d4e116b23961a67a1d1be',
      measurementId: 'G-8TWGEGQHMM',
    }

    const app = initializeApp(firebaseConfig)
    analytics = getAnalytics(app)

    TagManager.initialize(defaultTagManagerArgs)
  }
}

// Hotjar
const isHotjarEnabled = appConfig.hotjar.enabled

const initHotjar = () => {
  if (isHotjarEnabled) {
    Hotjar.init(appConfig.hotjar.siteId, appConfig.hotjar.version)
  }
}

// Sentry
const isSentryEnabled = appConfig.prod

const initSentry = () => {
  Sentry.init({
    enabled: isSentryEnabled,
    dsn: 'https://6f80b0639da02de81f18e2ed5e1c612d@o4508166640238592.ingest.us.sentry.io/4508166646988800',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/api\.otrperformance\.com\/v1/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const initializeInstruments = () => {
  initFirebase()
  initHotjar()
  initSentry()
}

initializeInstruments()

export const identify = (
  userId: string,
  isInternalUser: boolean | undefined = undefined
) => {
  if (isHotjarEnabled) {
    Hotjar.identify(userId, {})
  }

  if (isSentryEnabled) {
    Sentry.setUser({ id: userId })
  }

  if (analytics) {
    setUserId(analytics, userId)
    TagManager.initialize({
      ...defaultTagManagerArgs,
      dataLayer: {
        user_properties: {
          userId,
          isInternalUser,
        },
      },
    })
  }
}

export const reset = () => {
  if (isHotjarEnabled) {
    Hotjar.identify(null, {})
  }

  if (isSentryEnabled) {
    Sentry.setUser(null)
  }

  if (analytics) {
    setUserId(analytics, null)
    TagManager.initialize({
      ...defaultTagManagerArgs,
    })
  }
}
