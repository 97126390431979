import {
  CommandResultV1PaginatedResponseV1,
  FaultCodeResultV1PaginatedResponseV1,
} from 'types/api/v1'
import {
  useFindCommands,
  useFindVehicle,
  useFindFaultCodeHistory,
  useVehicleSearch,
} from 'utils/vehicles'
import { InfoDetail, InfoPanel } from './info-panel'
import { useEffect, useMemo, useState } from 'react'

const VehicleInfoPanel = () => {
  const { vin } = useFindVehicle()
  const { faultCodeId } = useVehicleSearch()

  const { faultCodes } = useFindFaultCodeHistory({ vin, enabled: !faultCodeId })
  const { data: commands } = useFindCommands(
    vin,
    undefined,
    undefined,
    !faultCodeId
  )
  const faultCodeResponse =
    faultCodes.data as FaultCodeResultV1PaginatedResponseV1
  const commandResponse = commands as CommandResultV1PaginatedResponseV1

  const defaultDetails = useMemo(() => {
    const defaults: InfoDetail[] = [{}]
    if (faultCodeId) {
      defaults.push({})
      defaults.push({})
    }
    return defaults
  }, [faultCodeId])

  const [details, setDetails] = useState<InfoDetail[]>(defaultDetails)

  useEffect(() => {
    const newDetails = []
    if (vin) {
      newDetails.push({
        title: 'VIN',
        value: vin,
        tooltip:
          'The VIN (Vehicle Identification Number) is a unique 17-character code that provides key details about the vehicle, including its year, make, model, engine, and configuration type.',
      })
    }
    if (!faultCodeId && faultCodeResponse) {
      newDetails.push({
        title: 'Fault Codes Logged',
        value: faultCodeResponse?.pagination?.totalCount?.toString() ?? '--',
        tooltip:
          'This is the total number of fault codes currently logged in our system, for the selected vehicle.',
      })
    }

    if (!faultCodeId && commandResponse) {
      newDetails.push({
        title: 'Commands Logged',
        value: commandResponse?.pagination?.totalCount?.toString() ?? '--',
        tooltip:
          'This is the total number of bi-directional commands sent and logged in our system, for the selected vehicle.',
      })
    }

    if (newDetails.length > 0) {
      setDetails(newDetails)
    }
  }, [vin, faultCodeResponse, commandResponse, faultCodeId])

  return <InfoPanel details={details} />
}

export { VehicleInfoPanel }
