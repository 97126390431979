import { LoginForm } from 'features/auth/login-form'

function Login() {
  return (
    <div className="w-full rounded-lg bg-white p-8 sm:w-2/3 sm:shadow-lg lg:w-1/3 xl:w-1/4">
      <LoginForm />
    </div>
  )
}

export { Login }
