import React, { useRef } from 'react'

import { NotFoundPlaceholder } from '../not-found-placeholder'
import { Badge, BadgeLevel } from 'ui/badge'
import { useFaultCodeDetail, useFindVehicle } from 'utils/vehicles'
import { useReactToPrint } from 'react-to-print'
import { PrintIcon } from 'ui/icons'
import { AnalyticsEvent, useAnalytics } from 'utils/use-analytics'

const SectionTitle = ({ children }: { children?: React.ReactNode }) => (
  <h3 className="mb-2 text-lg font-medium leading-7 text-[#2E2925]">
    {children}
  </h3>
)
const SectionContents = ({ children }: { children?: React.ReactNode }) => (
  <div className="text-sm font-medium leading-5 text-[#6C757D]">{children}</div>
)

const Section = ({ children }: { children?: React.ReactNode }) => (
  <div className="mb-6">{children}</div>
)

const FaultCodeDetail = () => {
  const { trackEvent } = useAnalytics()

  const { faultCode, faultCodeDetail, isLoading } = useFaultCodeDetail()
  const { vin } = useFindVehicle()

  const procedure = faultCodeDetail?.procedure
    ?.split(/\r\n|\r|\n/)
    .map((line) => line.replace(/^\d+\.\s*/, ''))
    .map((line, index) => (
      <li key={index} className="mb-1">
        {line}
      </li>
    ))

  const parseLineBreaks = (text: string) => {
    return text?.split(/\r\n|\r|\n/).map((line, index) => (
      <p key={index} className="mb-1">
        {line}
      </p>
    ))
  }

  const printRef = useRef<HTMLDivElement>(null)
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'm-6',
    documentTitle: `fault-code-${faultCode?.id}`,
  })

  const handlePrint = () => {
    trackEvent(AnalyticsEvent.SELECT_PDF_PRINT)
    print()
  }

  return (
    <>
      {faultCode && (
        <div className="mb-6 rounded-lg bg-white py-6 pl-5 pr-3 shadow-2 md:px-8 md:py-8">
          <div ref={printRef} className="print:bg-white">
            <div className="hidden print:mb-8 print:flex print:items-center print:justify-between">
              <div className="rounded-lg border border-gray-200 bg-white px-4 py-2">
                <div className="text-xs font-medium leading-5 text-gray-500">
                  VIN
                </div>
                <div className="text-xl font-semibold leading-6 text-gray-900">
                  {vin}
                </div>
              </div>
              <img src="/images/otr-cloud.png" alt="Logo" className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className="pb-2 text-2xl font-semibold leading-[38px] text-[#101828]">
                {faultCodeDetail?.name ??
                  faultCodeDetail?.overview ??
                  faultCode.name}
              </div>
            </div>

            <div className=" ">
              <div className="flex flex-wrap items-center justify-between border-b border-t border-[#EAECF0]  ">
                <div className="flex flex-row py-2 text-sm font-medium text-[#2E2925]">
                  {faultCode.label}
                </div>

                <div className="flex w-full flex-row py-2 text-sm font-medium text-[#2E2925] md:w-auto">
                  <Badge
                    level={
                      faultCode?.isActive ? BadgeLevel.WARNING : BadgeLevel.INFO
                    }
                    className="mr-2"
                  >
                    {faultCode?.isActive ? 'Active' : 'Inactive'}
                  </Badge>
                  <div>{faultCode?.startedAt}</div>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between border-b border-[#EAECF0] py-2">
                <div className="text-sm font-medium leading-5 text-[#2E2925]">
                  {faultCodeDetail?.codeSourceDescription ??
                    faultCode.codeSourceDescription}
                </div>
                {faultCode.codeCount && (
                  <Badge level={BadgeLevel.PRIMARY}>
                    {faultCode.codeCount} occurence
                    {faultCode.codeCount > 2 && 's'}
                  </Badge>
                )}
              </div>
            </div>

            {faultCodeDetail ? (
              <div className="pt-6 lg:w-2/3 xl:w-1/2 print:w-full">
                {faultCodeDetail?.name && (
                  <Section>
                    <SectionTitle>Overview</SectionTitle>
                    <SectionContents>
                      {faultCodeDetail?.overview}
                    </SectionContents>
                  </Section>
                )}
                {faultCodeDetail?.description &&
                  faultCodeDetail.description !== faultCodeDetail.overview && (
                    <Section>
                      <SectionTitle>Description</SectionTitle>
                      <SectionContents>
                        {parseLineBreaks(faultCodeDetail.description)}
                      </SectionContents>
                    </Section>
                  )}
                {faultCodeDetail?.symptom && (
                  <Section>
                    <SectionTitle>Symptom</SectionTitle>
                    <SectionContents>
                      {parseLineBreaks(faultCodeDetail.symptom)}
                    </SectionContents>
                  </Section>
                )}
                {faultCodeDetail?.troubleshooting && (
                  <Section>
                    <SectionTitle>Troubleshooting</SectionTitle>
                    <SectionContents>
                      {parseLineBreaks(faultCodeDetail.troubleshooting)}
                    </SectionContents>
                  </Section>
                )}
                {faultCodeDetail?.procedure &&
                  faultCodeDetail?.procedure?.length > 0 && (
                    <Section>
                      <SectionTitle>Repair Procedure</SectionTitle>
                      <SectionContents>
                        <ul className="list-inside list-decimal">
                          {procedure}
                        </ul>
                      </SectionContents>
                    </Section>
                  )}
                {faultCodeDetail?.additionalInfo && (
                  <Section>
                    <SectionTitle>Additional Information</SectionTitle>
                    <SectionContents>
                      {parseLineBreaks(faultCodeDetail?.additionalInfo)}
                    </SectionContents>
                  </Section>
                )}

                <button
                  onClick={handlePrint}
                  className="mt-8 flex items-center rounded-lg bg-[#339933] px-10 py-2 text-sm font-medium leading-none text-white shadow-sm transition-colors duration-300 ease-in-out hover:bg-otr-secondary print:hidden"
                >
                  <PrintIcon className="mr-[10px]" color="white" />
                  PDF Print
                </button>
              </div>
            ) : (
              !isLoading && (
                <NotFoundPlaceholder
                  className="h-96"
                  title="No information"
                  description="We currently do not have any more information at this time
                  about this fault code."
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  )
}

export { FaultCodeDetail }
