import { useState, useEffect } from "react"
import ReactDOM from "react-dom"

const Tooltip = ({
  content,
  isVisible,
  anchorEl,
}: {
  content: React.ReactNode
  isVisible: boolean
  anchorEl: HTMLElement | null
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

  useEffect(() => {
    if (anchorEl && isVisible) {
      const rect = anchorEl.getBoundingClientRect()

      // Position the tooltip below the anchor element
      setTooltipPosition({
        top: rect.bottom + 5, // 5px below the anchor
        left: rect.left + (rect.width / 2) - 128, // Center horizontally
      })
    }
  }, [isVisible, anchorEl])

  if (!isVisible) return null

  return ReactDOM.createPortal(
    <div
      className="fixed z-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      style={{
        top: `${tooltipPosition.top}px`,
        left: `${tooltipPosition.left}px`,
      }}
    >
      {content}
    </div>,
    document.body
  )
}

export { Tooltip }
