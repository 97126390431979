import {
  createFlagOverridesFromMap,
  OverrideBehaviour,
  PollingMode,
  SettingValue,
} from 'configcat-react'

import { appConfig } from 'app-config'
import { ConfigCatProvider, LogLevel } from 'configcat-react'

import { createConsoleLogger } from 'configcat-react'
import { isEmpty } from 'utils/string-helpers'

const defaultFlagOverrides: FlagOverrides = {
  faultCodeOverviewTooltip: true,
  vehicleDetailInfoTab: true,
  showAdditionalVehicleProperties: true,
  latestFaultCodes: false,
}

export type FlagOverrides = Record<string, NonNullable<SettingValue>>

function RemoteConfigProvider({
  children,
  flagOverrides,
}: {
  children: React.ReactNode
  flagOverrides?: FlagOverrides
}) {
  const logger = createConsoleLogger(
    appConfig.dev ? LogLevel.Debug : LogLevel.Error
  )
  const configCatOffline =
    isEmpty(appConfig.configCat.sdkKey) || !appConfig.configCat.enabled

  const sdkKey = configCatOffline ? 'dev' : appConfig.configCat.sdkKey

  const overrides = configCatOffline
    ? createFlagOverridesFromMap(
        {
          ...defaultFlagOverrides,
          ...flagOverrides,
        },
        OverrideBehaviour.LocalOnly
      )
    : undefined

  return (
    <ConfigCatProvider
      sdkKey={sdkKey}
      options={{
        logger,
        pollIntervalSeconds: 180,
        offline: configCatOffline,
        flagOverrides: overrides,
      }}
      pollingMode={PollingMode.AutoPoll}
    >
      {children}
    </ConfigCatProvider>
  )
}

export { RemoteConfigProvider }
