import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { PrintIcon } from 'ui/icons'
import { AnalyticsEvent, useAnalytics } from 'utils/use-analytics'

const useVehiclePrint = (printName: string) => {
  const { trackEvent } = useAnalytics()
  const printRef = useRef<HTMLDivElement>(null)

  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'm-6',
    documentTitle: printName,
  })

  const handlePrint = () => {
    trackEvent(AnalyticsEvent.SELECT_PDF_PRINT)
    print()
  }

  const PrintButton = ({ className }: { className?: string }) => (
    <button
      onClick={handlePrint}
      className={`flex items-center justify-center rounded-lg border border-gray-300 px-4 py-3 text-sm font-medium leading-5 text-[#2E2925] shadow-sm transition-colors duration-300 ease-in-out hover:bg-[#F0F2F4] ${className} print:hidden`}
    >
      <PrintIcon className="mr-[10px]" />
      PDF Print
    </button>
  )

  const PrintWrapper = ({
    vin,
    children,
  }: {
    vin?: string | null
    children: React.ReactNode
  }) => {
    return (
      <div ref={printRef}>
        <div className="hidden print:mb-6 print:flex print:items-center print:justify-between">
          <div className="rounded-lg border border-gray-200 bg-white px-4 py-2">
            <div className="text-xs font-medium leading-5 text-gray-500">
              VIN
            </div>
            <div className="text-xl font-semibold leading-6 text-gray-900">
              {vin}
            </div>
          </div>
          <img src="/images/otr-cloud.png" alt="Logo" className="h-12" />
        </div>
        {children}
      </div>
    )
  }

  return { PrintButton, PrintWrapper }
}

export { useVehiclePrint }
