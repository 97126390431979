import { Link } from 'react-router-dom'
import { ListIcon, LogoutIcon } from './icons'
import { useAuth } from 'context/auth-context'

function Navigation() {
  const { user, logout } = useAuth()

  return (
    <div className=" box-border flex h-screen w-72 flex-col items-start border-r border-gray-200 bg-white p-0">
      <Link
        to="/"
        className="mb-6 ml-5 mt-8 flex flex-col items-start rounded-xl  px-1 hover:bg-gray-200"
      >
        <img src="/images/otr-cloud.png" alt="Logo" className="h-12" />
      </Link>

      <Link
        to="/"
        className="mx-4 flex w-[248px] items-center rounded-lg bg-[#F9FAFB] px-3 py-2"
      >
        <ListIcon />
        <span className="order-2 ml-[15px] w-[66px] text-[16px]  font-semibold  text-[#344054]">
          Vehicles
        </span>
      </Link>
      <div className="mx-4 mt-auto w-[248px] border-t px-2 pb-8 pt-6">
        <div>
          <div className="flex items-center justify-between text-sm font-semibold leading-5 text-[#344054]">
            <span>
              {`${user?.identity?.firstName} ${user?.identity?.lastName}`}
            </span>
            <button
              role="button"
              onClick={() => void logout()}
              aria-label="Logout"
            >
              <LogoutIcon />
            </button>
          </div>
          <div className="break-words text-sm leading-5 text-[#475467]">
            {user?.identity?.email}
          </div>
        </div>
      </div>
    </div>
  )
}

export { Navigation }
