import { useState } from 'react'

function FloatingLabelInput({
  id,
  type = 'text',
  label,
  autoFocus = false,
  className = '',
  onChange,
}: {
  id: string
  type: string
  label: string
  autoFocus?: boolean
  className?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const [isFocused, setIsFocused] = useState(false)
  const [hasValue, setHasValue] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasValue(e.target.value.length > 0)
    onChange?.(e)
  }

  return (
    <div className={`relative ${className}`}>
      <label
        htmlFor={id}
        className={`absolute -top-2 left-2 bg-white px-1 transition-all ${isFocused || hasValue ? 'text-xs' : 'invisible'} text-gray-500`}
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        className="h-10 w-full rounded border-2 px-2 py-4 text-lg outline-none focus:border-otr-primary"
        placeholder={isFocused ? '' : label}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        autoFocus={autoFocus}
        onChange={handleChange}
      />
    </div>
  )
}

export { FloatingLabelInput }
