import {
  DirectionType,
  getCommandLabelByCode,
  useVehicles,
  useFindVehicles,
  useVehicleSearch,
  usePagination,
} from 'utils/vehicles'
import { useAuth } from 'context/auth-context'
import { formatDateString } from 'utils/date-helpers'
import { VehicleActivityV1, VehiclesPaginatedResponseV1 } from 'types/api/v1'
import {
  CalendarIcon,
  DownArrowIcon,
  PlayIcon,
  TruckIcon,
  UpArrowIcon,
} from 'ui/icons'
import { useEffect, useRef, useState } from 'react'
import { Badge, BadgeLevel } from 'ui/badge'
import { TableCell, TableHeaderCell, TableRow } from 'ui/table'
import { isEmpty } from 'utils/string-helpers'
import Skeleton from 'react-loading-skeleton'
import { useOtrAdminControls } from 'features/impersonate/use-otr-admin-controls'
import { Paginator } from './paginator'
import { Scrollable } from 'ui/scrollable'
import { NotFoundPlaceholder } from './not-found-placeholder'
import { AnalyticsEvent, useAnalytics } from 'utils/use-analytics'
import { useFeatureFlag } from 'utils/use-feature-flag'

const getPlaceholderVehicles = (count: number) => {
  return Array(count)
    .fill(null)
    .map(() => ({}) as VehicleActivityV1)
}

const doesVehicleHaveData = (vehicle: VehicleActivityV1) => {
  return (
    vehicle.vin !== undefined ||
    vehicle.lastActive !== undefined ||
    vehicle.activeFaultCodesCount !== undefined ||
    vehicle.lastCommandRun !== undefined
  )
}

function usePreviousValue(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function VehiclesTable() {
  const { trackEvent } = useAnalytics()
  const { direction, toggleDirection } = useVehicles()

  const { clearPage } = usePagination()
  const { impersonatedEmail } = useOtrAdminControls()
  const { selectVehicle } = useVehicleSearch()

  const displayCount = 10

  const { isSuperAdmin } = useAuth()

  const [vehicles, setVehicles] = useState<VehicleActivityV1[]>(
    getPlaceholderVehicles(displayCount)
  )

  const { data, isLoading, isSuccess, isError, error } =
    useFindVehicles(displayCount)

  const vehiclesResponse = data as VehiclesPaginatedResponseV1

  const handleClick = (vehicleId: string) => {
    trackEvent(AnalyticsEvent.SELECT_VEHICLE, {
      vehicleId,
    })
    selectVehicle(vehicleId)
  }

  useEffect(() => {
    if (vehiclesResponse?.vehicles) {
      setVehicles(vehiclesResponse.vehicles)
    }
  }, [vehiclesResponse])

  const placeholderText =
    isSuperAdmin && isEmpty(impersonatedEmail) && !isLoading
      ? 'Please enter a user email address to view their vehicles.'
      : isSuccess && vehiclesResponse?.pagination?.totalCount == 0
        ? 'No vehicles found.'
        : isError && typeof error === 'string'
          ? error
          : isError
            ? 'Ooops there was an error loading your vehicles'
            : ''

  const previousSearchedEmail = usePreviousValue(impersonatedEmail)
  useEffect(() => {
    if (
      isSuperAdmin &&
      previousSearchedEmail !== impersonatedEmail &&
      isEmpty(impersonatedEmail)
    ) {
      clearPage()
      setVehicles(getPlaceholderVehicles(displayCount))
    }
  }, [impersonatedEmail, previousSearchedEmail, isSuperAdmin, clearPage])

  const { value: activeFaultCodesCountEnabled } = useFeatureFlag(
    'latestFaultCodes',
    false
  )

  return (
    <div className="rounded-x  flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg border border-gray-200 bg-white shadow-custom">
      {!isEmpty(placeholderText) ? (
        <NotFoundPlaceholder className="h-96" description={placeholderText} />
      ) : (
        <>
          <Scrollable className="overflow-auto">
            <div className="table w-full">
              <div className="sticky top-0 z-10 table-header-group bg-white text-xs font-medium leading-[18px] text-[#6C757D] ">
                <TableHeaderCell>
                  <TruckIcon /> <span className="ml-[9.5px]"> VIN </span>
                </TableHeaderCell>
                <TableHeaderCell>
                  <CalendarIcon className="mr-[9.5px]" />
                  Last Active
                  {toggleDirection && (
                    <button
                      onClick={toggleDirection}
                      className="ml-2 rounded  border border-white p-2 hover:border-otr-primary-dark hover:text-stone-100 hover:shadow focus:outline-none focus:ring-2 focus:ring-otr-secondary"
                      aria-label={
                        direction === DirectionType.DESC.toString()
                          ? 'Sorted by most recently active to oldest'
                          : 'Sorted by oldest active to most recent'
                      }
                    >
                      {direction === DirectionType.DESC.toString() ? (
                        <DownArrowIcon />
                      ) : (
                        <UpArrowIcon />
                      )}
                    </button>
                  )}
                </TableHeaderCell>
                {activeFaultCodesCountEnabled && (
                  <TableHeaderCell>
                    <span className="h-[19px]"></span># {''}
                    Active Fault Codes
                  </TableHeaderCell>
                )}
                <TableHeaderCell>
                  <PlayIcon className="mr-[9.5px]" />
                  Last Command Ran
                </TableHeaderCell>
              </div>

              <>
                {vehicles.map((vehicle, index) => {
                  const isLastRow = index === vehicles.length - 1
                  const cellClasses = isLastRow ? '' : 'border-b'
                  return (
                    <TableRow
                      key={
                        isEmpty(vehicle?.vehicle?.vehicleId)
                          ? Math.random().toString(36).slice(2, 11)
                          : vehicle?.vehicle?.vehicleId
                      }
                      onSelect={
                        vehicle?.vehicle?.vehicleId
                          ? () => {
                              handleClick(vehicle?.vehicle?.vehicleId ?? '')
                            }
                          : undefined
                      }
                      className={` ${isLoading && doesVehicleHaveData(vehicle) ? 'bg-[#F0F2F4]' : index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                    >
                      <TableCell
                        className={`${cellClasses} text-sm font-medium leading-5`}
                      >
                        {vehicle?.vin === undefined ? (
                          <Skeleton width={162} />
                        ) : (
                          vehicle.vin
                        )}
                      </TableCell>
                      <TableCell
                        className={`${cellClasses} text-sm font-medium leading-5`}
                      >
                        {vehicle.vin === undefined ? (
                          <Skeleton width={122} />
                        ) : vehicle.lastActive ? (
                          formatDateString(vehicle.lastActive)
                        ) : (
                          ''
                        )}
                      </TableCell>
                      {activeFaultCodesCountEnabled && (
                        <TableCell className={cellClasses}>
                          {vehicle?.activeFaultCodesCount === undefined ? (
                            <Skeleton width={22} />
                          ) : (
                            <Badge level={BadgeLevel.WARNING}>
                              {vehicle.activeFaultCodesCount}
                            </Badge>
                          )}
                        </TableCell>
                      )}
                      <TableCell className={cellClasses}>
                        {vehicle.lastCommandRun === undefined ? (
                          <Skeleton width={132} />
                        ) : vehicle.lastCommandRun ? (
                          <Badge level={BadgeLevel.SUCCESS}>
                            {getCommandLabelByCode(vehicle.lastCommandRun)}
                          </Badge>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            </div>
          </Scrollable>
          <Paginator
            pagination={vehiclesResponse?.pagination}
            showPageNumbers={true}
          />
        </>
      )}
    </div>
  )
}

export { VehiclesTable }
