import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from 'context/auth-context'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FloatingLabelInput } from 'ui/floating-label-input'
import {
  Activation,
  ActivationResult,
  useActivateAdapter,
} from 'utils/vehicles'

function ActivationForm() {
  const [form, setForm] = useState<Activation>({})
  const { user } = useAuth()
  const {
    mutate,
    isError,
    isPending,
    error,
    data,
    reset,
  }: {
    mutate: (form: Activation) => void
    isError: boolean
    isPending: boolean
    error: any
    data: ActivationResult | undefined
    reset: () => void
  } = useActivateAdapter()
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      reset()
    }
    if (errorMessage) {
      setErrorMessage('')
    }
    setForm({ ...form, [e.target.id]: e.target.value })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    form.firstName = user?.identity?.firstName
    form.lastName = user?.identity?.lastName
    form.phone = user?.identity?.phone
    mutate(form)
  }

  useEffect(() => {
    if (error?.errors && error.errors.length > 0) {
      setErrorMessage(error.errors[0])
    } else {
      setErrorMessage('')
    }
  }, [error])

  const accountCreated = data?.success

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit} className="" noValidate>
        <h1 className=" mb-3 text-center text-xl font-bold">
          Activate your new adapter!
        </h1>
        {errorMessage ? (
          <div className="rounded bg-red-500 p-2 text-white">
            {errorMessage}
          </div>
        ) : (
          <div className="h-9"></div>
        )}
        <FloatingLabelInput
          id="activationCode"
          type="text"
          label="Enter your activation code"
          autoFocus={true}
          className=" mb-12 mt-2"
          onChange={handleChange}
        />
        <div className="flex justify-center">
          <button
            type="submit"
            className="w-2/3 rounded-2xl bg-otr-primary py-2 text-white shadow-lg hover:bg-otr-primary-dark focus:outline-none focus:ring-2 focus:ring-otr-secondary"
          >
            Activate
            {isPending && (
              <FontAwesomeIcon icon={faSpinner} spin className="ml-2" />
            )}
          </button>
        </div>
      </form>
    )
  }

  return (
    <div className=" ">
      {accountCreated ? (
        <div className="text-center">
          <div className="text-xl font-bold">
            Congratulations your adapter has been activated.
          </div>
          <div className="mt-4">
            Connect your adapter and go to your {''}
            <Link to="/vehicles" className=" text-blue-500 hover:underline">
              vehicles
            </Link>
            {''} to continue.
          </div>
        </div>
      ) : (
        renderForm()
      )}
    </div>
  )
}

export { ActivationForm }
