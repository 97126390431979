import { SignUpForm } from 'features/auth/sign-up-form'

function SignUp() {
  return (
    <>
      <SignUpForm />
    </>
  )
}

export { SignUp }
