import { Routes, Route, Link } from 'react-router-dom'
import { Login } from 'pages/login'
import { SignUp } from 'pages/sign-up'
import { ResetPassword } from 'pages/reset-password'
function UnauthenticatedApp() {
  return (
    <div className="flex min-h-screen items-center justify-center sm:bg-stone-50">
      <div className="flex w-full flex-col items-center">
        <Link to="/">
          <img
            src="/images/otr-cloud.png"
            alt="Logo"
            className="mb-5 h-14 w-auto"
          />
        </Link>
        <Routes>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="*" element={<Login />} />
          <Route path="/forgot-password" element={<ResetPassword />} />
        </Routes>
      </div>
    </div>
  )
}

export { UnauthenticatedApp }
