import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useAuth, useClient } from 'context/auth-context'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import {
  CommandResultV1,
  FaultCodeResultDetailV1,
  FaultCodeResultV1NonPaginatedCollectionResponseV1,
  FaultCodeResultV1PaginatedResponseV1,
  UserActivationV1,
  UserCreateActivationResultV1,
  VehiclesPaginatedResponseV1,
  VehicleV1,
} from 'types/api/v1'
import { RequestConfig } from './api-client'
import { useOtrAdminControls } from 'features/impersonate/use-otr-admin-controls'
import { useEffect, useMemo, useState } from 'react'
import { isEmpty } from './string-helpers'
import { FaultCodeViewModel } from 'types/fault-codes'
import { AnalyticsEvent, useAnalytics } from './use-analytics'
import { useFeatureFlag } from './use-feature-flag'

export enum FaultCodeFilter {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum DirectionType {
  ASC = 'asc',
  DESC = 'desc',
}

export enum VehicleDetailTab {
  FAULT_CODES_LATEST = '1',
  FAULT_CODES_HISTORY = '2',
  COMMANDS_HISTORY = '3',
  VEHICLE_INFO = '4',
}

export interface Activation
  extends Omit<UserActivationV1, 'email' | 'password'> {}
export interface ActivationResult extends UserCreateActivationResultV1 {}

export const unifiedCommandLabels: Record<number, string> = {
  101: 'OEM Request',
  201: 'Reset Fault Codes',
  202: 'Reset Generic Fault Codes',
  301: 'Reset Aftertreatment',
  302: 'Reset EGR Derate',
  303: 'Reset DEF Derate',
  304: 'Reset DPF Ash Accumulator',
  305: 'Reset SCR Accumulator',
  306: 'Aftertreatment Maintenance Filter Installation',
  307: 'Aftertreatment Maintenance Reset All',
  308: 'Install a New DPF',
  309: 'Install a Cleaned DPF',
  310: 'Purge SCR/DEF Module',
  401: 'Forced DPF Regen',
  402: 'Forced SCR Regen',
  403: 'Crystal DEF Sublimation',
  404: 'Forced SCR Efficiency Regen',
}

function getUnifiedCommandLabel(commandCode: number): string | null {
  return unifiedCommandLabels[commandCode]
}

function getLegacyCommandLabel(command: CommandResultV1): string | null {
  if (command?.deviceType === 'Android') {
    switch (command.command) {
      case 0:
        return 'DPF_START'
      case 1:
        return 'RESET_FAULTS'
      case 2:
        return 'RESET_AFTERTREATMENT'
      case 3:
        return 'DPF_STOP'
      case 4:
        return 'RESET_DPF_ASH'
      case 5:
        return 'RESET_SCR'
      case 6:
        return 'RESET_NOX'
      case 7:
        return 'RESET_DEF_DERATE'
      case 8:
        return 'RESET_EGR_DERATE'
      case 9:
        return 'RESET_EGR'
      case 10:
        return 'PURGE_SCR_DATA'
      case 11:
        return 'INSTALL_CLEAN_DPF'
      case 12:
        return 'AFTERTREATMENT_MAINTENANCE_FILTER'
      case 13:
        return 'AFTERTREATMENT_MAINTENANCE_RESET_ALL'
      case 14:
        return 'START_DEF_CRYSTAL_SUBLIMATION'
      case 15:
        return 'START_SCR_REGEN'
      case 16:
        return 'SCR_TEST'
      default:
        return null
    }
  } else if (command?.deviceType === 'iOS') {
    switch (command.command) {
      case 3:
        return 'DPF_START'
      case 1:
        return 'RESET_FAULTS'
      case 2:
        return 'RESET_AFTERTREATMENT'
      case 4:
        return 'resetEpa10EgrDerate'
      case 5:
        return 'resetEpa13EgrDerate'
      case 6:
        return 'resetEpa17EgrDerate'
      case 7:
        return 'resetEpa10DefDerate'
      case 8:
        return 'resetEpa13DefDerate'
      case 9:
        return 'resetEpa17DefDerate'
      case 10:
        return 'RESET_EGR_DERATE'
      case 11:
        return 'RESET_DEF_DERATE'
      case 12:
        return 'RESET_DPF_ASH'
      case 13:
        return 'RESET_SCR'
      case 14:
        return 'CUMMINS_RESET_FAULTS'
      case 15:
        return 'genericResetFaults'
      case 16:
        return 'AFTERTREATMENT_MAINTENANCE_FILTER'
      case 17:
        return 'AFTERTREATMENT_MAINTENANCE_RESET_ALL'
      case 18:
        return 'INSTALL_DPF_FILTER'
      case 19:
        return 'INSTALL_CLEAN_DPF'
      case 20:
        return 'PURGE_SCR_DATA'
      case 21:
        return 'START_SCR_REGEN'
      case 22:
        return 'START_DEF_CRYSTAL_SUBLIMATION'
      case 23:
        return 'SCR_TEST'
      default:
        return null
    }
  }
  return null
}

function getCommandLabel(command: CommandResultV1): string {
  let commandLabel = null

  if (command.command) {
    commandLabel = getUnifiedCommandLabel(command.command)
    if (!commandLabel) {
      commandLabel = getLegacyCommandLabel(command)
    }
  }

  if (!commandLabel) {
    commandLabel = 'Unknown Command'
  }
  return commandLabel
}

function getCommandLabelByCode(commandCode: number): string {
  const commandLabel = getUnifiedCommandLabel(commandCode)
  if (!commandLabel) {
    return 'Unknown Command'
  }
  return commandLabel
}

const queryConfig = {
  staleTime: 1000 * 60 * 5,
  cacheTime: 1000 * 60 * 30,
}
function buildQuery(
  queryKey: string[],
  queryFn: QueryFunction<unknown, string[], never>
) {
  return { queryKey, queryFn, ...queryConfig }
}

const buildSearchQueryString = (
  searchRequest: SearchRequest,
  isSuperAdmin = false
) => {
  let queryString = ''
  if (searchRequest.limit) {
    queryString += `limit=${searchRequest.limit}`
  }
  if (searchRequest.sort) {
    queryString += `${queryString ? '&' : ''}sort=${searchRequest.sort}`
  }
  if (searchRequest.direction) {
    queryString += `${queryString ? '&' : ''}direction=${searchRequest.direction}`
  }
  if (searchRequest.isActive !== undefined) {
    queryString += `${queryString ? '&' : ''}is_active=${searchRequest.isActive}`
  }

  queryString += `${queryString ? '&' : ''}include_not_synced=true`
  if (!searchRequest.email && isSuperAdmin) {
    queryString += '&all_users=true'
  }

  if (searchRequest.cursor) {
    queryString += `${queryString ? '&' : ''}cursor=${searchRequest.cursor}`
  }
  return encodeURI(queryString ? `?${queryString}` : queryString)
}

const buildAdapterSearchQueryString = (searchRequest: SearchRequest) => {
  let queryString = ''

  if (searchRequest.cursor) {
    queryString += `${queryString ? '&' : ''}cursor=${searchRequest.cursor}`
  } else {
    if (searchRequest.limit) {
      queryString += `limit=${searchRequest.limit}`
    }
    if (searchRequest.sort) {
      queryString += `${queryString ? '&' : ''}sort=${searchRequest.sort}`
    }
    if (searchRequest.direction) {
      queryString += `${queryString ? '&' : ''}direction=${searchRequest.direction}`
    }
  }

  return encodeURI(queryString ? `?${queryString}` : queryString)
}

export interface SearchRequest {
  vin?: string
  email?: string | null
  limit?: string
  sort?: string
  isActive?: boolean
  direction?: string
  pageLink?: string
  cursor?: string
}

const useFindCommands = (
  vin?: string | null,
  cursor?: string,
  count = 10,
  enabled = true
) => {
  const { isSuperAdmin } = useAuth()
  const client = useClient()
  const config: any = {}
  const { impersonatedEmail } = useOtrAdminControls()

  const defaultCommandSearchRequest = useMemo(
    () => ({
      limit: count.toString(),
      email: impersonatedEmail,
    }),
    [count, impersonatedEmail]
  )
  const [searchRequest, setSearchRequest] = useState<SearchRequest>(
    defaultCommandSearchRequest
  )

  useEffect(() => {
    const searchRequest = {
      ...defaultCommandSearchRequest,
      vin,
      cursor: isEmpty(cursor) ? undefined : cursor,
    } as SearchRequest

    if (isSuperAdmin) {
      searchRequest.email = impersonatedEmail ?? ''
    }

    setSearchRequest(searchRequest)
  }, [
    vin,
    defaultCommandSearchRequest,
    cursor,
    impersonatedEmail,
    isSuperAdmin,
  ])

  let otrFilterQuery = ''
  if (searchRequest.email) {
    otrFilterQuery += `email=${searchRequest.email}`
  }
  if (searchRequest.vin) {
    otrFilterQuery += `${otrFilterQuery ? '&' : ''}vin=${searchRequest.vin}`
  }
  if (otrFilterQuery) {
    config.headers = { 'Otr-Filters': otrFilterQuery }
  }

  const queryString = buildSearchQueryString(searchRequest, isSuperAdmin)
  return useQuery({
    enabled: enabled && !isEmpty(searchRequest.vin),
    ...buildQuery(['commands', JSON.stringify(searchRequest)], () =>
      client(`v1/adapters/commands${queryString}`, config)
    ),
  })
}

interface FindFaultCodeHistoryOptions {
  vin?: string | null
  count: number
  faultCodeFilter?: FaultCodeFilter | null
  cursor?: string
  enabled?: boolean
}

const defaultOptions: FindFaultCodeHistoryOptions = {
  vin: undefined,
  count: 10,
  cursor: undefined,
  faultCodeFilter: undefined,
  enabled: true,
}

const useFindFaultCodeHistory = (
  options: Partial<FindFaultCodeHistoryOptions> = {}
) => {
  const { count, enabled, vin, faultCodeFilter, cursor } = {
    ...defaultOptions,
    ...options,
  }
  const { isSuperAdmin } = useAuth()
  const { impersonatedEmail } = useOtrAdminControls()
  const client = useClient()

  const defaultFaultCodeSearchRequest = useMemo(() => {
    return {
      limit: count.toString(),
      email: impersonatedEmail,
    } as SearchRequest
  }, [count, impersonatedEmail])
  const config: any = {}
  const [searchRequest, setSearchRequest] = useState<SearchRequest>(
    defaultFaultCodeSearchRequest
  )

  useEffect(() => {
    const searchRequest = {
      ...defaultFaultCodeSearchRequest,
      cursor,
      vin,
    } as SearchRequest

    if (isSuperAdmin) {
      searchRequest.email = impersonatedEmail ?? ''
    }

    if (faultCodeFilter === FaultCodeFilter.ACTIVE) {
      searchRequest.isActive = true
    } else if (faultCodeFilter === FaultCodeFilter.INACTIVE) {
      searchRequest.isActive = false
    } else {
      searchRequest.isActive = undefined
    }

    setSearchRequest(searchRequest)
  }, [
    vin,
    defaultFaultCodeSearchRequest,
    faultCodeFilter,
    cursor,
    impersonatedEmail,
    isSuperAdmin,
  ])

  let otrFilterQuery = ''
  if (searchRequest.email) {
    otrFilterQuery += `email=${searchRequest.email}`
  }
  if (searchRequest.vin) {
    otrFilterQuery += `${otrFilterQuery ? '&' : ''}vin=${searchRequest.vin}`
  }
  if (searchRequest.sort) {
    otrFilterQuery += `${otrFilterQuery ? '&' : ''}sort=${searchRequest.sort}`
  }

  if (otrFilterQuery) {
    config.headers = { 'Otr-Filters': otrFilterQuery }
  }

  const queryString = buildSearchQueryString(searchRequest, isSuperAdmin)
  const faultCodes = useQuery({
    enabled: enabled && !isEmpty(searchRequest.vin),
    ...buildQuery(['fault-code-history', JSON.stringify(searchRequest)], () =>
      client(`v1/adapters/fault-codes${queryString}`, config)
    ),
  })

  const [enhancedFaultCodeIds, setEnhancedFaultCodeIds] = useState<string[]>([])

  useEffect(() => {
    const response = faultCodes?.data as FaultCodeResultV1PaginatedResponseV1
    const enhancedFaultCodeIds: string[] = []
    response?.data?.forEach((faultCode) => {
      if (faultCode.faultCodeId) {
        if (faultCode.enhanced && !isEmpty(faultCode.faultCodeId)) {
          enhancedFaultCodeIds.push(faultCode.faultCodeId)
        }
      }
    })
    setEnhancedFaultCodeIds(enhancedFaultCodeIds)
  }, [faultCodes?.data])

  const enhancedFaultCodeDetails = useFindFaultCodeDetails(enhancedFaultCodeIds)

  return { faultCodes, enhancedFaultCodeDetails }
}

const useFindLatestFaultCodes = (vin?: string | null, enabled = true) => {
  const { isSuperAdmin } = useAuth()
  const { impersonatedEmail } = useOtrAdminControls()
  const client = useClient()

  const defaultFaultCodeSearchRequest = useMemo(
    () => ({
      email: impersonatedEmail,
    }),
    [impersonatedEmail]
  )

  const [searchRequest, setSearchRequest] = useState<SearchRequest>(
    defaultFaultCodeSearchRequest
  )

  useEffect(() => {
    const searchRequest = {
      ...defaultFaultCodeSearchRequest,
      vin,
    } as SearchRequest

    if (isSuperAdmin) {
      searchRequest.email = impersonatedEmail ?? ''
    }

    setSearchRequest(searchRequest)
  }, [vin, defaultFaultCodeSearchRequest, impersonatedEmail, isSuperAdmin])

  const config: any = {}

  let otrFilterQuery = ''

  if (searchRequest.email) {
    otrFilterQuery += `email=${searchRequest.email}`
  }

  if (searchRequest.vin) {
    otrFilterQuery += `${otrFilterQuery ? '&' : ''}vin=${searchRequest.vin}`
  }

  if (otrFilterQuery) {
    config.headers = { 'Otr-Filters': otrFilterQuery }
  }

  let queryString = '?include_not_synced=true'
  if (isSuperAdmin) {
    queryString += `&all_users=true`
  }

  const faultCodes = useQuery({
    enabled: enabled && searchRequest.vin !== undefined,
    ...buildQuery(['latest-fault-codes', JSON.stringify(searchRequest)], () =>
      client(`v1/adapters/fault-codes/latest${encodeURI(queryString)}`, config)
    ),
  })

  const [enhancedFaultCodeIds, setEnhancedFaultCodeIds] = useState<string[]>([])

  useEffect(() => {
    const response =
      faultCodes?.data as FaultCodeResultV1NonPaginatedCollectionResponseV1
    const enhancedFaultCodeIds: string[] = []
    response?.data?.map((faultCode) => {
      if (
        faultCode.enhanced &&
        !isEmpty(faultCode.faultCodeId) &&
        faultCode.faultCodeId
      ) {
        enhancedFaultCodeIds.push(faultCode.faultCodeId)
      }
    })
    setEnhancedFaultCodeIds(enhancedFaultCodeIds)
  }, [faultCodes?.data])

  const enhancedFaultCodeDetails = useFindFaultCodeDetails(enhancedFaultCodeIds)

  return { faultCodes, enhancedFaultCodeDetails }
}

const useFindFaultCodeDetails = (faultCodeIds: string[]) => {
  const client = useClient()

  const result = useQuery({
    enabled: faultCodeIds.length > 0,
    ...buildQuery(['fault-code-details', JSON.stringify(faultCodeIds)], () =>
      client(
        `v1/adapters/fault-codes/details?ids=${encodeURI(faultCodeIds.join(','))}`
      )
    ),
  })

  return result
}

const useActivateAdapter = () => {
  const client = useClient()
  return useMutation({
    mutationFn: (activation: Activation) => {
      return client('v1/users/activation', {
        data: activation,
      })
    },
  })
}

// @TODO: use this enum
// enum AdapterSearchParamKeys {
//   VIN = 'vin',
//   EMAIL = 'email',
// }

function usePagination() {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get('page') ?? '1', 10)
  const cursor = searchParams.get('cursor') ?? ''

  const setPage = (cursor: string, page?: number) => {
    if (page === 1) {
      searchParams.delete('cursor')
      searchParams.delete('page')
    } else {
      searchParams.set('cursor', cursor)
      if (page) {
        searchParams.set('page', page.toString())
      }
    }
    setSearchParams(searchParams)
  }

  const clearPage = (providedSearchParams?: URLSearchParams) => {
    const params = providedSearchParams ?? searchParams
    if (params.get('page')) {
      params.delete('page')
    }
    if (params.get('cursor')) {
      params.delete('cursor')
    }
    if (!providedSearchParams) {
      setSearchParams(params)
    }
  }

  return {
    page,
    cursor,
    setPage,
    clearPage,
  }
}

function useVehicles() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { clearPage } = usePagination()

  const direction = searchParams.get('direction')
    ? (searchParams.get('direction') as DirectionType)
    : 'desc'

  const toggleDirection = () => {
    searchParams.set(
      'direction',
      direction === DirectionType.ASC ? DirectionType.DESC : DirectionType.ASC
    )
    clearPage(searchParams)
    setSearchParams(searchParams)
  }

  return {
    direction,
    toggleDirection,
  }
}

function useVehicleSearch() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { clearPage } = usePagination()
  const { trackEvent } = useAnalytics()

  const selectVehicle = (vehicleId: string) => {
    navigate(`/vehicles/${vehicleId}`, {
      state: {
        from: location,
      },
    })
  }

  const setFaultCodeFilter = (filter: FaultCodeFilter) => {
    searchParams.set('faultCodeFilter', filter)
    clearPage(searchParams)
    setSearchParams(searchParams)
  }

  const selectTab = (tab: string) => {
    trackEvent(AnalyticsEvent.SELECT_VEHICLE_DETAIL_TAB, {
      tab,
    })
    searchParams.set('tab', tab)
    searchParams.delete('faultCodeFilter')
    clearPage(searchParams)
    setSearchParams(searchParams)
  }

  const tab = searchParams.get('tab') as VehicleDetailTab

  const faultCodeFilter = searchParams.get('faultCodeFilter')
    ? (searchParams.get('faultCodeFilter') as FaultCodeFilter)
    : null

  const { vehicleId } = useParams<{ vehicleId: string }>()

  const selectFaultCode = (faultCodeId: string) => {
    searchParams.set('faultCode', faultCodeId)
    setSearchParams(searchParams)
  }

  const clearFaultCode = () => {
    searchParams.delete('faultCode')
    setSearchParams(searchParams)
  }

  const faultCodeId = searchParams.get('faultCode')

  return {
    selectVehicle,
    faultCodeFilter,
    setFaultCodeFilter,
    vehicleId,
    faultCodeId,
    selectFaultCode,
    clearFaultCode,
    tab,
    selectTab,
  }
}

function useFaultCodeDetail() {
  const { vin } = useFindVehicle()
  const { tab, faultCodeId, faultCodeFilter } = useVehicleSearch()
  console.log('fault code id', faultCodeId)
  const { cursor } = usePagination()
  console.log('tab', tab)
  const { value: latestFaultCodesEnabled } = useFeatureFlag(
    'latestFaultCodes',
    false
  )
  const {
    faultCodes: historyFaultCodes,
    enhancedFaultCodeDetails: historyEnhancedFaultCodeDetails,
  } = useFindFaultCodeHistory({
    enabled: tab === VehicleDetailTab.FAULT_CODES_HISTORY,
    vin,
    cursor: isEmpty(cursor) ? undefined : cursor,
    faultCodeFilter,
  })

  const {
    faultCodes: latestFaultCodes,
    enhancedFaultCodeDetails: latestEnhancedFaultCodeDetails,
  } = useFindLatestFaultCodes(vin, tab === VehicleDetailTab.FAULT_CODES_LATEST)

  const isLoading =
    historyFaultCodes?.isLoading ||
    latestFaultCodes?.isLoading ||
    historyEnhancedFaultCodeDetails?.isLoading ||
    latestEnhancedFaultCodeDetails?.isLoading

  const faultCode = useMemo(() => {
    const faultCodes =
      tab === VehicleDetailTab.FAULT_CODES_HISTORY || !latestFaultCodesEnabled
        ? (historyFaultCodes?.data as FaultCodeResultV1PaginatedResponseV1)
            ?.data
        : (
            latestFaultCodes?.data as FaultCodeResultV1NonPaginatedCollectionResponseV1
          )?.data
    const result = faultCodes?.find((fc) => fc.faultCodeId === faultCodeId)
    return result ? new FaultCodeViewModel(result) : null
  }, [
    tab,
    historyFaultCodes?.data,
    latestFaultCodes?.data,
    faultCodeId,
    latestFaultCodesEnabled,
  ])

  const faultCodeDetail = useMemo(() => {
    const faultCodeDetails =
      tab === VehicleDetailTab.FAULT_CODES_HISTORY || !latestFaultCodesEnabled
        ? (historyEnhancedFaultCodeDetails?.data as FaultCodeResultDetailV1[])
        : (latestEnhancedFaultCodeDetails?.data as FaultCodeResultDetailV1[])
    return (
      faultCodeDetails?.find((fcd) => fcd.faultCodeId === faultCodeId) ?? null
    )
  }, [
    tab,
    historyEnhancedFaultCodeDetails?.data,
    latestEnhancedFaultCodeDetails?.data,
    faultCodeId,
    latestFaultCodesEnabled,
  ])

  return { faultCode, faultCodeDetail, isLoading }
}

function useFindVehicles(count: number) {
  const { impersonatedEmail } = useOtrAdminControls()
  const { cursor } = usePagination()
  const { direction } = useVehicles()
  const { isSuperAdmin } = useAuth()
  const queryClient = useQueryClient()

  const defaultSearchRequest = useMemo(() => {
    return {
      limit: count.toString(),
      direction: direction,
      sort: 'last_active',
      email: impersonatedEmail,
      cursor: cursor,
    } as SearchRequest
  }, [count, direction, impersonatedEmail, cursor])
  const [searchRequest, setSearchRequest] = useState(defaultSearchRequest)

  useEffect(() => {
    const request = defaultSearchRequest
    if (isSuperAdmin) {
      request.email = impersonatedEmail ?? ''
    }
    if (direction) {
      request.direction = direction
    }

    request.cursor = cursor

    setSearchRequest(request)
  }, [impersonatedEmail, isSuperAdmin, direction, defaultSearchRequest, cursor])

  const isEnabled =
    !isSuperAdmin || (isSuperAdmin && !isEmpty(impersonatedEmail))

  const client = useClient()
  const config: RequestConfig = {}

  if (isSuperAdmin && searchRequest.email) {
    config.headers = { 'Otr-Filters': `email=${searchRequest.email}` }
  }

  const queryString = buildAdapterSearchQueryString(searchRequest)
  const response = useQuery({
    enabled: isEnabled,
    ...buildQuery(['vehicles', JSON.stringify(searchRequest)], () =>
      client(`v1/vehicles${queryString}`, config)
    ),
  })

  if (response.isSuccess && response.data) {
    const data = response.data as VehiclesPaginatedResponseV1
    data?.vehicles?.map((vehicle) => {
      if (vehicle?.vehicle?.vehicleId) {
        queryClient.setQueryData(
          ['vehicle', vehicle.vehicle?.vehicleId],
          vehicle.vehicle
        )
      }
    })
  }
  return response
}

function useFindVehicle() {
  const { vehicleId } = useParams<{ vehicleId: string }>()
  const client = useClient()

  const response = useQuery({
    enabled: !!vehicleId,
    ...buildQuery(['vehicle', vehicleId!], () =>
      client(`v1/vehicles/${vehicleId}`)
    ),
  })

  let vin = null
  if (response.isSuccess && response.data) {
    const data = response.data as VehicleV1
    vin = data.vin
  }

  return { vin, ...response }
}

export {
  useFindVehicles,
  useFindCommands,
  useFindFaultCodeHistory,
  useFindLatestFaultCodes,
  getCommandLabel,
  useVehicles,
  useFindFaultCodeDetails,
  useActivateAdapter,
  getCommandLabelByCode,
  useVehicleSearch,
  usePagination,
  useFaultCodeDetail,
  useFindVehicle,
}
