import * as React from 'react'
import { client } from 'utils/api-client'
import { useAsync } from 'utils/use-async'
import * as auth from 'utils/auth-service'
import { QueryClient } from '@tanstack/react-query'
import { CreateSessionResultV1, OtrUserRoleV1, UserLoginV1 } from 'types/api/v1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useOtrAdminControls } from 'features/impersonate/use-otr-admin-controls'
import { useIntercom } from 'react-use-intercom'
import { appConfig } from 'app-config'
import { identify, reset } from 'instrument'
import { getToken } from 'utils/auth-service'
import { AuthenticationError } from 'utils/api-client'

const AuthContext = React.createContext(null)
AuthContext.displayName = 'AuthContext'

const isSuperUser = (user?: auth.AuthUser): boolean => {
  return (
    user?.otrUserRoles?.some(
      (role: OtrUserRoleV1) => role.slug === 'otr-superuser'
    ) ?? false
  )
}

interface AuthContextData {
  user?: auth.AuthUser
  login: (userLogin: UserLoginV1) => Promise<void>
  logout: () => Promise<void>
  refreshUser: () => Promise<auth.AuthUser | null>
  isSuperAdmin: boolean
  error?: any
}

export function AuthProvider(props: any) {
  const { boot, shutdown } = useIntercom()
  const queryClient = new QueryClient()
  const {
    data: user,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
    status,
    error,
  } = useAsync()
  const { flush } = useOtrAdminControls()

  const clearData = () => {
    shutdown()
    setData(null)
    flush()
    reset()
    queryClient.clear()
  }

  const refreshUser = async () => {
    const refreshedUser = await auth.refresh()
    if (refreshedUser) {
      setData(refreshedUser)
      return refreshedUser
    } else {
      await logout()
      return null
    }
  }
  const logout = async () => {
    clearData()
    await auth.logout()
  }

  const getUser = React.useCallback(async (): Promise<auth.AuthUser | null> => {
    const token = getToken()?.token
    if (!token) {
      return null
    }

    try {
      const userResponse = await client('v1/users/subscriptions', { token })
      return {
        ...userResponse,
        accessTokens: [getToken()],
      } as auth.AuthUser
    } catch (error) {
      if (error instanceof AuthenticationError) {
        return await refreshUser()
      }
      throw error
    }
  }, [])

  React.useEffect(() => {
    run(getUser())
  }, [run, getUser])

  const login = (userLogin: UserLoginV1): Promise<void> => {
    return auth.login(userLogin).then((user: CreateSessionResultV1) => {
      setData(user)
    })
  }

  if (isLoading || isIdle) {
    return (
      <div className="flex h-screen items-center justify-center text-stone-300">
        <FontAwesomeIcon icon={faSpinner} spin size={'4x'} />
      </div>
    )
  }

  if (isError) {
    clearData()
    const value = {
      error,
      login,
      logout,
      refreshUser,
      isSuperAdmin: false,
    }
    return <AuthContext.Provider {...props} value={value} />
  }

  if (isSuccess) {
    const authUser = user as auth.AuthUser
    const isSuperAdmin = isSuperUser(authUser)
    const value: AuthContextData = {
      user: authUser,
      login,
      logout,
      refreshUser,
      isSuperAdmin,
    }
    if (appConfig.intercom.enabled && authUser && !isSuperAdmin) {
      boot({
        email: authUser?.identity?.email ?? undefined,
        name: `${authUser?.identity?.firstName} ${authUser?.identity?.lastName}`,
        userId: authUser?.identity?.otrUserId ?? undefined,
        createdAt: authUser?.createdAt ?? undefined,
      })
    }
    if (authUser?.identity?.otrUserId) {
      identify(
        authUser?.identity?.otrUserId,
        isSuperAdmin ||
          authUser?.identity?.email?.includes('@otrperformance.com')
      )
    }
    return <AuthContext.Provider {...props} value={value} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

export function useAuth(): AuthContextData {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export function useClient() {
  const { user, logout, refreshUser } = useAuth()
  const token = user?.accessTokens?.find(
    (t: any) => t.type === 'AccessToken'
  )?.token

  return React.useCallback(
    async (endpoint: any, config?: any) => {
      try {
        return await client(endpoint, { ...config, token })
      } catch (error) {
        if (error instanceof AuthenticationError) {
          // Try to refresh the user
          const refreshedUser = await refreshUser()
          // If refresh successful, retry the original request with new token
          const newToken = refreshedUser?.accessTokens?.find(
            (t: any) => t.type === 'AccessToken'
          )?.token
          if (newToken) {
            return await client(endpoint, { ...config, token: newToken })
          }
        }
        throw error
      }
    },
    [token, logout, refreshUser]
  )
}
