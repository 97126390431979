import { AuthProvider } from './auth-context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { OtrAdminControlsProvider } from 'features/impersonate/otr-admin-controls-context'
import { IntercomProvider } from 'react-use-intercom'
import { appConfig } from 'app-config'
import { FlagOverrides, RemoteConfigProvider } from './remote-config-provider'

const queryConfig = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry(failureCount: any, error: any) {
      if (error.status === 404) return false
      else if (failureCount < 3) return true
      else return false
    },
  },
}
const defaultQueryClient = new QueryClient({ defaultOptions: queryConfig })

function AppProviders({
  children,
  queryClient = defaultQueryClient,
  flagOverrides,
}: {
  children: React.ReactNode
  queryClient?: QueryClient
  flagOverrides?: FlagOverrides
}) {
  return (
    <RemoteConfigProvider flagOverrides={flagOverrides}>
      <IntercomProvider
        appId={appConfig.intercom.appId}
        shouldInitialize={appConfig.intercom.enabled}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <OtrAdminControlsProvider>
            <AuthProvider>{children}</AuthProvider>
          </OtrAdminControlsProvider>
        </QueryClientProvider>
      </IntercomProvider>
    </RemoteConfigProvider>
  )
}

export { AppProviders }
